
import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import axios from 'axios'
import apiUrl from '../../services/apiUrl'
import { useDispatch } from 'react-redux'
import { setsearchText } from '../../features/search/searchTextSlice'
import { setStateList } from '../../features/search/stateListSlice'
import { updateSelectedState } from '../../features/search/selectedstateSlice'
import { setCityList } from '../../features/search/cityListSlice'
import { setSelectedCity } from '../../features/search/selectedCitySlice'
import { setExperiance } from '../../features/search/experianceSlice'


import '../../assets/css/autosuggetion/doctorSearch.scoped.css'
import { Link } from 'react-router-dom'

import HandleOutsideClick from "react-outside-click-event";

function DocotorSearchBox(props) {
    const dispatch = useDispatch()
    const selectedCountry = useSelector(state => state.slectedCountry.value)
    const stateList = useSelector(state => state.stateList.value)
    const selectedState = useSelector(state => state.selectedcityState.value)
    const citylist = useSelector(state => state.cityList.value)
    const selectedCity = useSelector(state => state.selectedCity.value)
    const searchText = useSelector(state => state.searchText.value)

    const [showSuggetion, setshowSuggetion] = useState(false)
    const [doctorSuggetion, setdoctorSuggetion] = useState([])
    const [suggetionTips, setsuggetionTips] = useState([])


    useEffect(() => {
        getStateList()
        setshowSuggetion(false)
    }, [selectedCountry])




    const suggtionController = (e) => {
        dispatch(setsearchText(e.target.value))
        getSuggetion()
    }


    const getSuggetion = async () => {
        setshowSuggetion(true)
        try {
            var response = await axios.post(apiUrl.getDoctorSuggetion, { search_text: searchText })
            if (response?.data?.doctors) {
                setdoctorSuggetion(response.data.doctors)
            }
            if (response?.data?.tips) {
                setsuggetionTips(response.data.tips)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const getStateList = async () => {
        try {
            var response = await axios.post(apiUrl.getStateListByCountry, { country_id: selectedCountry })
            if (response?.data?.status && response?.data?.data?.countryBasedState) {
                dispatch(setStateList(response.data.data.countryBasedState))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getCityList = async (state) => {
        try {
            var response = await axios.post(apiUrl.getCityByState, { country_id: selectedCountry, state_id: state })
            if (response.data?.data?.city) {
                dispatch(setCityList(response.data.data.city))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const selectCity = (e) => {
        dispatch(updateSelectedState(e.target.value))
        getCityList(e.target.value)
    }

    return (
        <><HandleOutsideClick
            onOutsideClick={() => {
                setshowSuggetion(false)

            }}
        >
            <div className="input-group column">
                <div className="col-md">
                    <input type="search" type="text" placeholder='Speciality' className="form-control custom-input-box shadow-none custom-search"
                        value={props.value}
                        onChange={(e) => suggtionController(e)} />
                </div>
                <div className="col-md">
                    <select className="form-select custom-select shadow-none" id="inputGroupSelect01" style={{ height: '85px' }}
                        onChange={selectCity} value={selectedState}>
                        <option defaultValue hidden>State</option>
                        {stateList.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}

                    </select>
                </div>
                <div className="col-md">
                    <select className="form-select custom-select shadow-none" id="inputGroupSelect01" style={{ height: '85px' }}
                        onChange={(e) => { dispatch(setSelectedCity(e.target.value)) }} value={selectedCity}>
                        <option defaultValue hidden>City</option>
                        {citylist.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}


                    </select>
                </div>
                <div className="col-md">
                    <select className="form-select custom-select last shadow-none" id="inputGroupSelect01" style={{ height: '85px', borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}
                        onChange={(e) => { dispatch(setExperiance(e.target.value)) }}>
                        <option selected hidden>Range of Experiance</option>
                        <option value="1">  1 Year</option>
                        <option value="3">  3 years</option>
                        <option value="5">  5 Years</option>
                        <option value="10">10 Years</option>
                        <option value="20">20 Years</option>

                    </select>
                </div>
            </div>


            {doctorSuggetion.length > 0 && showSuggetion && <section>
                <div className="row col-12 doctors m-0">
                    <div className="doctor-left">

                        {doctorSuggetion.map(item =>
                            <div className="doctors-list" key={item.id}>
                                <div className="doctor-img">
                                    <img src={`${apiUrl.host}/storage/${item?.image}`} alt="" />
                                </div>
                                <div className="ds-detail">
                                    <h2>{item?.name}</h2>
                                    <div className="content">{item?.department_name || ''} <br />Over {item?.experience || ''} years of experience <br /> {item?.verified_badge == 1 && 'Verified'} <br />{item?.workingHospitalDetails?.title || ''}, {item?.workingHospitalDetails?.HospitalcityDetails?.name || ''}</div>
                                </div>
                                <div className="links text-end">
                                    <Link onClick={() => setshowSuggetion(false)} to={`/doctor/${item.id}`} className="btcn1">Know Your Doctor</Link>
                                    <Link to={`/doctor/${item.id}`} className="btcn">Enquire Now</Link>
                                </div>
                            </div>)}


                    </div>
                    <div className="doctor-right">
                        <h2 className="tip-tl">Health Tips</h2>

                        {suggetionTips.map(item => <div className="tip-list" key={item.id}>
                            <img src={`${apiUrl.host}/storage/${item?.image}`} />
                            <p>{item?.description} </p>
                            <a href="#" className="t-600 t1">View More</a>
                        </div>)}



                    </div>
                </div>
            </section>}
        </HandleOutsideClick>

        </>

        // <div className="row">
        //     <input type="text" aria-label="First name" className="form-control" />
        //     <input type="text" aria-label="Last name" className="form-control" />


        //     <div className="col m-0">
        //         <input type="search" className="form-control m-0 custom-input-box" placeholder='Search Here'
        //         value={props.value}
        //         onChange={(e) => { dispatch(setsearchText(e.target.value)) }}/>
        //     </div>

        //     <div className="col-md-4 m-0">
        //         <select className="form-select form-select-lg mb-3 custom-select" style={{ height: '100%' }}
        //         onChange={(e) => { dispatch(setSelectedDept(e.target.value)) }}>
        //             <option selected hidden>Choose Department</option>
        //             {departmentList.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}

        //         </select>
        //     </div>
        // </div>
    )

}

export default DocotorSearchBox
