import { createSlice } from '@reduxjs/toolkit'

export const isSearchSlice = createSlice({
  name: 'isSearch',
  initialState: {
    value: 0
  },
  reducers: {
    setIsSearch: (state,action) => {
      state.value = action.payload
    }
   
  }
})

export const { setIsSearch } = isSearchSlice.actions

export default isSearchSlice.reducer