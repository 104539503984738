import React, { useEffect, useState } from 'react'
import Hero from '../components/common/hero'
import { useParams } from 'react-router-dom'

import '../assets/css/doctor/doctorsdetail.scoped.css'
import doctorPlaceHolder from '../assets/images/doctorplaceholder.png'
import placeHolder from '../assets/images/placeholder.png'
import starFill from '../assets/images/star-fill.svg'
import verified from '../assets/images/verified.svg'

import parse from 'html-react-parser'
import Faq from '../components/common/faq'
import axios from 'axios'
import apiUrl from '../services/apiUrl'
import Doctorenquiry from '../components/enquiry/doctorenquiry'


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";


function Doctordetailspage() {
    const { doctorId } = useParams()
    const [doctorDetails, setdoctorDetails] = useState({})
    const [similiarDoctor, setsimiliarDoctor] = useState([])
    const [awardPublication, setawardPublication] = useState({})
    const [coreSpeciality, setcoreSpeciality] = useState([])
    const [patientVoice, setpatientVoice] = useState([])
    const [doctorHospital, setdoctorHospital] = useState({})

    useEffect(async () => {
        getDoctorDetaile()
        getSimiliarDoctor()
        getAwardPubliction()
        getCoreSpeciality()
        getDoctorPatientVoice()
        getDoctorsHospital()

    }, [doctorId])





    const getDoctorDetaile = async () => {
        try {
            var response = await axios.post(apiUrl.getDoctorDetails, { doctor_id: doctorId })
            console.log(response.data)
            if (response.data?.data?.doctor) {
                setdoctorDetails(response.data.data.doctor)
            }
        } catch (error) {
            console.log(error)

        }
    }

    const getSimiliarDoctor = async () => {
        try {
            var response = await axios.post(apiUrl.getSimiliarDoctor, { doctor_id: doctorId })
            if (response.data?.data?.similarDoctor) {
                setsimiliarDoctor(response.data.data.similarDoctor)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAwardPubliction = async () => {
        try {
            var response = await axios.post(apiUrl.getDoctorAwardPublication, { doctor_id: doctorId })
            if (response.data?.data?.awardsandPublication) {
                setawardPublication(response.data.data.awardsandPublication[0])
            }
        } catch (error) {
            console.log('award', error)
        }
    }

    const getCoreSpeciality = async () => {
        try {
            var response = await axios.post(apiUrl.getDoctorSpeciality, { doctor_id: doctorId })
            if (response.data?.data?.coreSpeciality) {
                setcoreSpeciality(response.data.data.coreSpeciality)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getDoctorPatientVoice = async () => {
        try {
            var response = await axios.post(apiUrl.getDoctorPatientVoice, { doctor_id: doctorId })
            if (response?.data?.data?.patientVoice) {
                setpatientVoice(response.data.data.patientVoice)
            }
        } catch (error) {

        }
    }

    const getDoctorsHospital = async () => {
        try {
            var response = await axios.post(apiUrl.getDoctorHospital, { doctor_id: doctorId })
            console.log('dhos',response.data)
            if (response.data?.data?.hospitalDetails) {
                 setdoctorHospital(response.data.data.hospitalDetails)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            Previous
        </button>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            Next
        </button>
    );

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrow: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,

        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };

    return (
        <>
            <Hero />
            <section>
                <div className="row main-pd">
                    <div className="col-8">
                        <section>
                            <div className="doctor-detail">
                                <div className="doctor-im">
                                    <img src={doctorDetails?.image ? `${apiUrl.host}/storage/${doctorDetails.image}` : doctorPlaceHolder} alt="" style={{ borderRadius: '100%' }} width="150" />
                                    <a href="#" className="ask-now t-700">Ask now</a>
                                </div>
                                <div className="ds-detail">
                                    <h2>{doctorDetails?.name}</h2>
                                    <p>{doctorDetails?.department_name || ""}</p>
                                    

                                    <p>{doctorDetails?.achievements || ''}</p>
                                    <p>{doctorDetails?.designation || ''}</p>

                                    <p>{doctorDetails?.specialization || ''}</p>
                                    <p>{doctorDetails?.qualification || " "}</p>
                                    <p>{doctorDetails?.countryDetails?.name || ''}</p>
                                    <p style={{ color: '#E6771B' }}><img src={starFill} alt="" style={{ marginTop: '-5px', width: '15px' }} /> Highly recommended</p>
                                    <p style={{ fontSize: '14px' }}>97% of patients gave this doctor {doctorDetails?.rating} stars</p>
                                </div>
                                <div className="verify">
                                    <div className="verify-left">
                                        <p className="t-600" style={{ fontSize: '12px' }}>Over all Rating</p>
                                        <p style={{ fontSize: '24px' }}>{doctorDetails.rating}</p>
                                        <div className="star">
                                            {Array(parseInt(doctorDetails.rating || 0)).fill().map((x, i) => i).map(item => <img key={item} src={starFill} alt="" />)}

                                        </div>
                                        <p className="t-600" style={{ color: "#058ED2", fontSize: "16px" }}>Reviews</p>
                                    </div>
                                    <div className="verify-right">
                                        {doctorDetails.verified_badge === 1 && <p className="t-600" style={{ fontSize: '11px' }}><img src={verified} alt="" /> Medical Registration Verified</p>}
                                        <p className="t-600" style={{ fontSize: '12px' }}><span style={{ color: '#4CAF50' }}>100%</span> Lorem Ipsum Dummy</p>
                                        <a href="#" className="enquire-now t-600">Enquire now</a>
                                    </div>
                                </div>
                                <p>{doctorDetails.meta_description}</p>
                            </div>
                        </section>

                        <section>
                            <div className="row m-0">
                                <ul className="mn-head">
                                    <li className="active">Awards/ Publications</li>
                                    <li><a href='#corespeciality' className='text-dark'>Specialization</a></li>
                                    <li><a href='#patientvoice' className='text-dark'>Patients Voice </a></li>
                                </ul>
                                <p className="p-0">{parse(`${awardPublication?.content ?? 'No Data to Show'}`)}</p>
                                <div className="tl-contr w-100 p-0">
                                    <h1 className="text-start float-start" id='corespeciality'>Core speciality</h1>
                                </div>
                                <ul className="core-contr p-0">
                                    {coreSpeciality.map(item =>
                                        <li key={item.id}><h2><img src={item?.logo ? `${apiUrl.host}/storage/${item.logo}` : placeHolder} width="70" height="70" alt="" /> {item.title}</h2></li>)}
                                    {coreSpeciality.length === 0 && <span>No Data to Show !</span>}
                                </ul>
                            </div>

                        </section>

                        <section>
                            <div className="row m-0 col-12 packages text-center">
                                <div className="tl-contr float-start w-100">
                                    <h1 className="text-start float-start">Similar Doctors</h1>
                                </div>
                                <div className="row">

                                    <Slider {...settings}>

                                        {similiarDoctor.map(item =>
                                            <div className="col-4" key={item.id}>
                                                <div className="item">
                                                    <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : doctorPlaceHolder} alt="" />
                                                    <h2>{item.name}</h2>
                                                    <h2 className="inline"><img src="images/hospital-place-icon.svg" alt="" /> Zentra Hospital, Delhi</h2>
                                                    <h3>{item.experience} Yrs of Exp. / Cardiac</h3>
                                                </div>
                                            </div>)}
                                    </Slider>

                                    {similiarDoctor.length === 0 && <span>No Data to Show !</span>}
                                </div>
                            </div>
                        </section>

                        <section>
                            <div className="col-12 text-center testi-contr">
                                <div className="tl-contr float-start w-100">
                                    <h1 className="text-start float-start" id='patientvoice'>Patient Voice</h1>
                                </div>
                                <div className="testimonial">
                                    {patientVoice.map(item =>
                                        <div className="col-12" key={item.id}>
                                            <div className="item">
                                                <div className="row">
                                                    <div className="col-6 pleft">
                                                        <img src={item?.author_image ? `${apiUrl.host}/storage/${item.author_image}` : placeHolder} alt="" />
                                                        <h2>{item.author}</h2>
                                                        {/* <p style={{color:"#E6771B"}}>Lorem ipsumdummy</p> */}
                                                        <p>{parse(`${item.description}`)}</p>
                                                    </div>

                                                    <div className="col-6 pright">
                                                        <iframe width="100%" height="220" src={item.link}
                                                            title="YouTube video player" frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen></iframe>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                                            labore et dolore</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}
                                </div>
                            </div>
                        </section>

                    </div>
                    <Doctorenquiry doctorId={doctorId} hospials={doctorHospital} />
                </div>
            </section>

            <Faq faqlist={[]} />
        </>
    )
}

export default Doctordetailspage
