import React, { useState, useEffect } from 'react'
import { Skeleton, message } from 'antd'
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';

import parse from 'html-react-parser';

import 'antd/lib/skeleton/style/index.css'
import 'antd/lib/message/style/index.css'

import hospitalPlaceIcon from '../../assets/images/hospital-place-icon.svg'
import hospitalIcon from '../../assets/images/hospital-icon.svg'
import packageIcon from '../../assets/images/package.png'
import placeHolder from '../../assets/images/placeholder.png'
import doctorPlaceHolder from '../../assets/images/doctorplaceholder.png'

import '../../assets/css/treatment/treatment.scoped.css'
import axios from 'axios'
import apiUrl from '../../services/apiUrl'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Treatment(props) {
    useEffect(async () => {
        getBestHospital()
        getBestDoctors()
        getPatienceVoice()
    }, [props.id])

    const [bestHospital, setbestHospital] = useState([])
    const [hospitalloading, sethospitalloading] = useState(true)
    const [bestDoctor, setbestDoctor] = useState([])
    const [doctorloading, setdoctorloading] = useState(true)
    const [treatmentDocs, settreatmentDocs] = useState('')
    const [submiting, setsubmiting] = useState(false)
    const { register, handleSubmit, reset } = useForm()
    const countryList = useSelector(state => state.countrylist.value)
    const [bestHospitalTemp, setbestHospitalTemp] = useState([])
    const [patientVoice, setpatientVoice] = useState([])
    const [patienceLoading, setpatienceLoading] = useState(true)
    const [diseasesList, setdiseasesList] = useState([])

    React.useEffect(() => {
        getDiseases()
    }, [])



    const getDiseases = async () => {
        try {
            var response = await axios.get(apiUrl.getDieseases)
            if (response?.data?.diseases) {
                setdiseasesList(response.data.diseases)
            }

        } catch (error) {
            console.log(error)
        }
    }


    const getBestHospital = async () => {
        try {
            var response = await axios.post(apiUrl.getBestHospitalForTreatementDieses, { treatment_id: props.id })
            if (response?.data?.status && response?.data?.data?.bestHospital) {
                setbestHospital(response.data.data.bestHospital)
                sethospitalloading(false)
                setbestHospitalTemp(response.data.data.bestHospital)

            }
        } catch (error) {
            console.log(error)
        }
    }

    const getBestDoctors = async () => {
        try {
            var response = await axios.post(apiUrl.getBestDoctorForTreatementDieses, { treatment_id: props.id })
            if (response.data.status && response.data.data.bestDoctor) {
                setbestDoctor(response.data.data.bestDoctor)
                setdoctorloading(false)
            }
        } catch (error) {
            console.log(error)

        }
    }

    const submitForm = async (data, e) => {
        setsubmiting(true)
        try {
            var fdata = new FormData();
            fdata.append('file', treatmentDocs)
            fdata.append('treatment_id', props.id)
            fdata.append('email', data.email)
            fdata.append('diseases_id', data.diseases)
            fdata.append('message', data.query)
            fdata.append('name', data.firstName)

            var response = await axios.post(apiUrl.setTreatmentEnquiry, fdata, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })

            if (response.data.status) {
                message.success(response.data.message || 'Enguiry Recorded Successfully', 3)
                setsubmiting(false)

            } else {
                setsubmiting(false)
                message.error('Failed Record Engquiry', 3)
            }

        } catch (error) {
            console.log(error)
            setsubmiting(false)
            message.error('Failed Record Engquiry', 3)



        } finally {
            e.target.reset();
            reset()
            settreatmentDocs('')
        }
    }

    const filterHospital = (id) => {
        // var hospitalData = bestHospitalTemp
        var filteredHospitalData = bestHospitalTemp.filter(item => item.countryId === id)
        setbestHospital(filteredHospitalData)

    }

    const getPatienceVoice = async () => {
        try {
            var response = await axios.post(apiUrl.getCms, { category_id: 2 })
            console.log(response?.data)
            if (response?.data?.data?.cms) {
                setpatientVoice(response.data.data.cms)
                setpatienceLoading(false)
            }
        } catch (error) {
            console.log(error)

        }
    }
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            Previous
        </button>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            Next
        </button>
    );

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrow: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,

        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };



    return (
        <div>
            <section className="treatment-section">
                <div className="row main-pd">
                    <div className="col-8">

                        <section>
                            <div className="col-12 best-doctor-contr best-hh">
                                <div className="tl-contr float-start w-100">
                                    <h1 className="text-start float-start">Best Hospital for this Disease</h1>
                                </div>
                                <div className="row">

                                    <Skeleton loading={hospitalloading} active>
                                        <Slider {...settings}>
                                            {bestHospital.map(item =>

                                                <div className="col-4" key={item.id}>
                                                    <div className="item">
                                                        <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" height="240" />
                                                        <h2>{item.title}</h2>
                                                        <h2 className="inline"><img src={hospitalPlaceIcon} alt="" /> {item.location}</h2>
                                                    </div>
                                                </div>
                                            )}

                                            {bestHospital.length == 0 && <h2>No Data to Show !</h2>}
                                        </Slider>
                                    </Skeleton>




                                </div>
                            </div>
                        </section>


                        <section>
                            <div className="col-12 best-doctor-contr text-center">
                                <div className="tl-contr float-start w-100">
                                    <h1 className="text-start float-start">Best Doctors for this Disease</h1>
                                </div>
                                <div className="row">

                                    <Skeleton loading={doctorloading} active>
                                        <Slider {...settings}>

                                            {bestDoctor.map(item =>
                                                <div className="col-4">
                                                    <div className="item">
                                                        <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : doctorPlaceHolder} alt="" />
                                                        <h2>{item.name}</h2>
                                                        <h2 className="inline"><img src={hospitalPlaceIcon} alt="" /> {item.address}</h2>
                                                        <h3>{item.experience} Experiance </h3>
                                                    </div>
                                                </div>)}
                                        </Slider>

                                    </Skeleton>

                                </div>
                            </div>
                        </section>



                        <div className="tl-contr float-start w-100">
                            <h1 className="row text-start float-start">Most Popular Package for this Disease</h1>
                        </div>


                        <div className="row packages">
                            <div className="col-6">
                                <div className="item-list">
                                    <div className="hospital">
                                        <img src={hospitalIcon} alt="" />
                                        <div className="c-one">Venkateshwar Hospital, India</div>
                                    </div>
                                    <div className="position-relative">
                                        <img src={packageIcon} alt="" />
                                    </div>
                                    <h2>Dental Package</h2>
                                    <div className="benefit">
                                        <h2>Benefits</h2>
                                        <ul>
                                            <li>2 Days Hospital</li>
                                            <li>5 Days Hospital</li>
                                            <li>Bollywood smile etc.</li>
                                        </ul>
                                        <div className="view">View Details</div>
                                    </div>
                                    <div className="botm-content">
                                        <p><span>Our Price</span>$ 900</p>
                                        <p className="text-end color">$ 900<span>(10%) Discount</span></p>
                                        <button type="button" className="book-now">Book @ 10% | Pay USD 90</button>
                                    </div>
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="item-list">
                                    <div className="hospital">
                                        <img src={hospitalIcon} alt="" />
                                        <div className="c-one">Venkateshwar Hospital, India</div>
                                    </div>
                                    <div className="position-relative">
                                        <img src={packageIcon} alt="" />
                                    </div>
                                    <h2>Dental Package</h2>
                                    <div className="benefit">
                                        <h2>Benefits</h2>
                                        <ul>
                                            <li>2 Days Hospital</li>
                                            <li>5 Days Hospital</li>
                                            <li>Bollywood smile etc.</li>
                                        </ul>
                                        <div className="view">View Details</div>
                                    </div>
                                    <div className="botm-content">
                                        <p><span>Our Price</span>$ 900</p>
                                        <p className="text-end color">$ 900<span>(10%) Discount</span></p>
                                        <button type="button" className="book-now">Book @ 10% | Pay USD 90</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section>
                            <div className="col-12 text-center testi-contr">
                                <div className="tl-contr float-start w-100">
                                    <h1 className="text-start float-start">Patient Voice</h1>
                                </div>
                                <Skeleton loading={patienceLoading} active>
                                    <div className="row testimonial">
                                        <div className="col-12">
                                            <div className="item">

                                                {patientVoice.map(item => <div className="row">
                                                    <div className="col-6 pleft">
                                                        <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" />
                                                        <h2>{item?.auther}</h2>
                                                        <p style={{ color: "#E6771B" }}>{item?.title}</p>
                                                        <p>{parse(`${item?.content || ''}`)}</p>
                                                    </div>
                                                    <div className="line"></div>

                                                    {item?.file || item?.link && <div className="col-6 pright">
                                                        <iframe width="100%" height="220" src={item?.link || item?.file}
                                                            title="YouTube video player" frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen >
                                                        </iframe>
                                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                                        labore et dolore</p> */}
                                                    </div>}


                                                </div>)}

                                            </div>
                                        </div>
                                    </div>

                                </Skeleton>

                            </div>
                        </section>

                        <div className="cn-section">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                            est laborum.
                        </div>

                    </div>
                    <div className="col-4 right-sec">

                        <div className="card mt-5 border-0" style={{ width: "18rem;", backgroundColor: '#EDF2F7' }}>
                            <div className="card-body">
                                <h5 className="card-title fw-bold">Filter by Country</h5>

                                <div className="mt-3">
                                    {countryList.map(item =>
                                        <div className="form-check" key={item.id}>
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                                                onChange={() => { filterHospital(item.id) }} />
                                            <label className="form-check-label">
                                                {item.name}
                                            </label>
                                        </div>)}

                                </div>

                            </div>
                        </div>

                        <div className="form-contr">

                            <form onSubmit={handleSubmit(submitForm)}>
                                <h2>May I Know About Your Medical Needs?</h2>
                                <input type="text" placeholder="Name" className="form" {...register("firstName")} />
                                <input type="email" placeholder="Email" className="form" {...register("email")} required />
                                <select placeholder="Diseases" className="form" {...register("diseases")}>
                                    <option selected hidden value="">Choose Diseases</option>
                                    {diseasesList.map(item => <option key={item.id} value={item.id}>{item.title}</option>)}
                                </select>
                                <textarea name="" id="" cols="30" rows="10" placeholder="Your Query" {...register("query")}></textarea>
                                <input type="file" onChange={(e) => { settreatmentDocs(e?.target?.files[0] || '') }} name="file-1[]" id="file-1" className="inputfile inputfile-1"
                                    accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                <label className="form mnt" htmlFor="file-1" >
                                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.1875 9.625V12.25H1.875V9.625H0V13.125C0 13.6089 0.42 14 0.9375 14H13.125C13.6434 14 14.0625 13.6089 14.0625 13.125V9.625H12.1875Z"
                                            fill="#213B4D" />
                                        <path
                                            d="M10.6651 3.64963L7.38381 0.149625C7.29475 0.055125 7.16631 0 7.03131 0C6.89631 0 6.76787 0.055125 6.67881 0.149625L3.39756 3.64963C3.27662 3.77913 3.24662 3.962 3.32349 4.11862C3.39943 4.27437 3.56631 4.375 3.75006 4.375H5.62506V10.0625C5.62506 10.304 5.83506 10.5 6.09381 10.5H7.96881C8.22756 10.5 8.43756 10.304 8.43756 10.0625V4.375H10.3126C10.4963 4.375 10.6632 4.27525 10.7391 4.11862C10.8151 3.962 10.7869 3.77825 10.6651 3.64963Z"
                                            fill="#213B4D" />
                                    </svg><span>{treatmentDocs.name || 'Choose a file…'}</span>
                                </label>



                                {submiting ?
                                    <button type="submit" placeholder="Ask from our experts" className="form">
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>  {' Submiting Data ...'}
                                    </button> :

                                    <button type="submit" placeholder="Ask from our experts" className="form">
                                        Ask from our experts
                                    </button>}

                                {/* <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>  */}
                            </form>

                        </div>
                        {/* <div className="recent-blog">
                            <h2>Recent Article / Blog</h2>
                            <div className="blog-list">
                                <img src={blogIcon} alt="" />
                                <h2>Lorem Ipsum Simplmmy</h2>
                                <div className="content">Neque porro quisquam est qui doloremipsum quia dolor</div>
                            </div>
                            <div className="blog-list">
                                <img src={blogIcon} alt="" />
                                <h2>Lorem Ipsum Simplmmy</h2>
                                <div className="content">Neque porro quisquam est qui doloremipsum quia dolor</div>
                            </div>
                        </div> */}
                    </div>

                </div>
            </section>

        </div>
    )
}

export default Treatment
