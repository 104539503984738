import { createSlice } from '@reduxjs/toolkit'

export const selectedStateSlice = createSlice({
  name: 'selectedState',
  initialState: {
    value: 0
  },
  reducers: {
    updateSelectedState: (state,action) => {
      state.value = action.payload
    }
   
  }
})

export const { updateSelectedState } = selectedStateSlice.actions

export default selectedStateSlice.reducer