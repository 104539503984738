import { configureStore } from '@reduxjs/toolkit'
import countryListReduser from '../features/search/countryListSlice'
import selectedCategoryReduser from '../features/search/selectedCategorySlice'
import selectedCountryReduser from '../features/search/selectedCountrySlice'
import selectedDeptReduser from '../features/search/selectedDept'
import searchTextSliceReduser from '../features/search/searchTextSlice'
import isSearchSliceReduser from '../features/search/searchListenSlice'
import selectedstateSliceReduser from '../features/search/selectedstateSlice'
import stateListSliceReduser from '../features/search/stateListSlice'
import cityListSliceReduser from '../features/search/cityListSlice'
import selectedCitySliceReduser from '../features/search/selectedCitySlice'
import experianceSliceReduser from '../features/search/experianceSlice'
import settingsSliceReduser from '../features/settingsSlice'





export default configureStore({
  reducer: {
    countrylist: countryListReduser,
    selectedCategory: selectedCategoryReduser,
    slectedCountry: selectedCountryReduser,
    selectedDept: selectedDeptReduser,
    searchText: searchTextSliceReduser,
    isSearch: isSearchSliceReduser,
    selectedcityState: selectedstateSliceReduser,
    stateList: stateListSliceReduser,
    cityList: cityListSliceReduser,
    selectedCity: selectedCitySliceReduser,
    doctorExeperiance: experianceSliceReduser,
    settings: settingsSliceReduser
  }
})