import React, { useState, useEffect } from 'react'

import bannerImg from '../assets/images/home-banner.png'
import bnDoctor from '../assets/images/bn-doctor.png'
import fad from '../assets/images/fad.png'
import doctorPlaceHolder from '../assets/images/doctorplaceholder.png'

import { Link } from 'react-router-dom'


import '../assets/css/doctor/doctorspeciality.scoped.css'
import axios from 'axios'
import apiUrl from '../services/apiUrl'
import { Skeleton } from 'antd'
import 'antd/lib/skeleton/style/index.css'

function TelemedicineDoctorPage() {
    const [doctorSpeciality, setdoctorSpeciality] = useState([])
    const [citys, setcitys] = useState([])
    const [selectedState, setselectedState] = useState('')
    const [searchText, setsearchText] = useState('')
    const [isLoading, setisLoading] = useState(true)

    useEffect(async () => {
        getSpecialityDoctor()
        getAllCity()
    }, [])

    const getSpecialityDoctor = async () => {
        setisLoading(true)
        try {
            var response = await axios.post(apiUrl.getTeleDoctor, { search_text: searchText, city_id: selectedState })
            if (response?.data?.data?.telemedicineDoctors) {
                setdoctorSpeciality(response.data.data.telemedicineDoctors)
            }
            setisLoading(false)

        } catch (error) {
            console.log(error)
            setisLoading(false)

        }
    }

    const getAllCity = async () => {
        try {
            var response = await axios.get(apiUrl.getAllCity)
            if (response?.data?.data?.cities) {
                setcitys(response.data.data.cities)
            }
        } catch (error) {
            console.error(error)
        }
    }


    return (
        <>
            <section>
                <div className="p-0 main-banner text-center">
                    <div className="speciality-bn">
                        <img src={bannerImg} />
                    </div>
                    <div className="banner-content row">
                        <div className="col-7 p-0">
                            <div className="search-contr">
                                <h2 className="text-start">Start to Search</h2>
                                <div className="search-input">
                                    <input type="search" placeholder="Search Doctors, Clinic, Hospital Here!" onChange={(e) => { setsearchText(e.target.value) }} />
                                    <select onChange={(e) => { setselectedState(e.target.value) }}>
                                        <option hidden>Choose City</option>
                                        {citys.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                                    </select>
                                    <button type="submit" onClick={() => { getSpecialityDoctor() }}><svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="45" height="45" rx="4.5" fill="#E6771B" stroke="#BCBEC0" />
                                        <path d="M33.6524 23.702L25.7059 31.6487C25.4791 31.8755 25.1768 32 24.8544 32C24.5317 32 24.2295 31.8753 24.0027 31.6487L23.2812 30.927C23.0545 30.7005 22.9297 30.398 22.9297 30.0755C22.9297 29.7531 23.0545 29.4404 23.2812 29.2139L27.9171 24.5679H13.1887C12.5247 24.5679 12 24.048 12 23.3838V22.3635C12 21.6993 12.5247 21.127 13.1887 21.127H27.9696L23.2814 16.455C23.0547 16.2282 22.9299 15.9339 22.9299 15.6114C22.9299 15.2892 23.0547 14.9906 23.2814 14.764L24.0029 14.0446C24.2297 13.8178 24.5319 13.6942 24.8546 13.6942C25.1769 13.6942 25.4793 13.8194 25.7061 14.0462L33.6526 21.9927C33.8799 22.2202 34.005 22.524 34.0041 22.8469C34.0048 23.1709 33.8799 23.4748 33.6524 23.702Z" fill="white" />
                                    </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-5 p-0">
                            <div className="search-right">
                                <img src={bnDoctor} alt="" />
                                {/* <a href="#" className="find-doctor">Find Doctors Now</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="row m-0 main-pd tsc-contr">

                    <div className="tl-contr float-start w-100">
                        <h1 className="text-start float-start">Find a Doctor</h1>
                        <p>Find experienced doctors across all specialties</p>
                    </div>

                    <Skeleton loading={isLoading} active>
                        {doctorSpeciality.map(item =>
                            <div className="l-one" key={item.id}>
                                <Link to={`/doctor/${item.id}`}>
                                <img src={item.image ? `${apiUrl.host}/storage/${item.image}` : doctorPlaceHolder} alt="" />
                                </Link>
                                <h3>{item.name}</h3>
                                <p>{item.qualification}</p>
                                
                            </div>)}

                        {doctorSpeciality.length === 0 && <h4>No Data to Show </h4>}

                    </Skeleton>


                </div>
            </section>




        </>
    )
}

export default TelemedicineDoctorPage
