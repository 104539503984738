import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'
import { setCountryList } from '../../features/search/countryListSlice'

import { Link } from 'react-router-dom';
import { setIsSearch } from '../../features/search/searchListenSlice'






// importing icons

import bannerImg from '../../assets/images/home-banner.png'
import axios from 'axios'
import apiUrl from '../../services/apiUrl'


import '../../assets/css/title.scoped.css'


function TitleBanner(props) {
    const navigate = useNavigate();
    const [showMenu, setshowMenu] = useState(false)

    const countryList = useSelector(state => state.countrylist.value)
    const selectdCategory = useSelector(state => state.selectedCategory.value)
    const selectedCountry = useSelector(state => state.slectedCountry.value)
    const selectedDept = useSelector(state => state.selectedDept.value)
    const searchText = useSelector(state => state.searchText.value)
    const isSearch = useSelector(state => state.isSearch.value)

    const dispatch = useDispatch()

    const getCountry = async () => {
        if (countryList.length === 0) {
            var response = await axios.get(apiUrl.getCountry)
            if (response?.status && response?.data?.data?.country) {
                dispatch(setCountryList(response.data.data.country))
            }
        }
    }

    const search = async () => {
        dispatch(setIsSearch(isSearch + 1))
       
       
        navigate('/treatment/list')

    }



    useEffect(() => {
        getCountry()
    }, [])

    return (
        <>
            <section>
                <div className="p-0 main-banner text-center">
                    <img src={bannerImg} alt="" className="banner-bg contact-bnr" />
                    <div className="banner-content plc" style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                        <h1>Contact US</h1>
                        <div className="content">It is a long established fact that a reader will be distracted by the readable 
content of a page when looking at its layout. </div>

                    </div>



                </div>



                {showMenu && <div className="d-menu" style={{ display: 'block' }}>
                    <ul>
                        <li><Link className="dropdown-item" to="/blog">Blog</Link></li>
                        <li><Link className="dropdown-item" to="/contact">Support / Contact US </Link></li>
                        <li><Link className="dropdown-item" to="/medicine">TeleMedicine</Link></li>
                        {/* <li><Link className="dropdown-item" to="/blog">Blog</Link></li> */}
                    </ul>
                </div>}

            </section>


        </>






    )
}

export default TitleBanner
