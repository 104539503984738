import React, { useState } from 'react'
import { useSelector } from 'react-redux'



import '../../assets/css/hospital/hospitalDetails.scoped.css'


import medicalTripicon from '../../assets/images/medical-trip.png'
import medicineIndia from '../../assets/images/medicines-india.png'


import { useForm } from 'react-hook-form'
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as Yup from 'yup';
import { message } from 'antd';
import 'antd/lib/message/style/index.css'
import axios from 'axios'
import apiUrl from '../../services/apiUrl'


function HospitalEnquiry(props) {

    const [docs, setdocs] = useState('')
    const [submiting, setsubmiting] = useState(false)
    //const countryList = useSelector(state => state.countrylist.value)
    const [diseasesList, setdiseasesList] = useState([])

    // const schema = Yup.object({
    //     email: Yup.string().email('Invalid Email').required('Email Required'),
    //     phone: Yup.number().transform((currentValue, originalValue) => {
    //         return originalValue === '' ? null : currentValue;
    //     })
    //         .nullable()
    //         .typeError('Must be a Phone number'),
    // })

    const { register, handleSubmit, reset,formState: { errors } } = useForm({})

    React.useEffect(() => {
      getDiseases()
    }, [])
    


    const getDiseases = async()=>{
        try {
            var response = await axios.get(apiUrl.getDieseases)
            if(response?.data?.diseases){
                setdiseasesList(response.data.diseases)
            }
            
        } catch (error) {
            console.log(error)
        }
    }

    const submitEnquiry = async (data,e) => {
        setsubmiting(true)
        try {
            var fdata = new FormData();
            fdata.append('file', docs)
            fdata.append('hospital_id', props.hospitalId)
            fdata.append('name', data.name)
            fdata.append('email', data.email)
            fdata.append('diseases_id', data.diseases)
            fdata.append('message', data.message)

            var response = await axios.post(apiUrl.postHospitalEnquiry, fdata, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })

            console.log(response.data)

            if (response.data.status) {
                message.success(response.data.message || 'Enquiry Recorded Successfully', 3)
                setsubmiting(false)

            } else {
                setsubmiting(false)
                message.error('Failed Record Enquiry', 3)
            }

        } catch (error) {
            console.log(error)
            setsubmiting(false)
            message.error('Failed Record Enquiry', 3)



        }finally{
            e.target.reset();
            setdocs('')
            reset()
        }

    }


    return (
        <>
            <div className="col-4 right-sec" id='booknow'>
                <div className="form-contr">
                    <form onSubmit={handleSubmit(submitEnquiry)}>
                        <h2>May I Know About Your Medical Needs?</h2>
                        <input type="text" placeholder="Name" className="form" {...register("name")} />
                        <input type="email" placeholder="Email" className="form" {...register("email")} required />
                        <select name="" id="" aria-placeholder="Diseases" className="form" {...register("diseases_id")}>
                            <option hidden value="">Choose Diseases</option>
                            {diseasesList.map(item => <option key={item.id} value={item.id}>{item.title}</option>)}
                        </select>
                        <textarea name="" id="" cols="30" rows="10" placeholder="Your Query" {...register("message")}></textarea>
                        <input type="file" id="file-1" onChange={(e) => { setdocs(e?.target?.files[0] || '') }} className="inputfile inputfile-1"
                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                        <label htmlFor="file-1" className="form mnt">
                            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.1875 9.625V12.25H1.875V9.625H0V13.125C0 13.6089 0.42 14 0.9375 14H13.125C13.6434 14 14.0625 13.6089 14.0625 13.125V9.625H12.1875Z"
                                    fill="#213B4D" />
                                <path
                                    d="M10.6651 3.64963L7.38381 0.149625C7.29475 0.055125 7.16631 0 7.03131 0C6.89631 0 6.76787 0.055125 6.67881 0.149625L3.39756 3.64963C3.27662 3.77913 3.24662 3.962 3.32349 4.11862C3.39943 4.27437 3.56631 4.375 3.75006 4.375H5.62506V10.0625C5.62506 10.304 5.83506 10.5 6.09381 10.5H7.96881C8.22756 10.5 8.43756 10.304 8.43756 10.0625V4.375H10.3126C10.4963 4.375 10.6632 4.27525 10.7391 4.11862C10.8151 3.962 10.7869 3.77825 10.6651 3.64963Z"
                                    fill="#213B4D" />
                            </svg><span>{docs.name || 'Choose a file…'}</span>
                        </label>
                        <button type="submit" className="form expert-btn" value="Ask from our experts">
                            {!submiting ? 'Ask from our experts' : <span className="spinner-border spinner-border-sm" role="status"> </span>}
                        </button>
                    </form>
                </div>
                <div className="trip">
                    <div className="trip-list trip-contr">
                        <img src={medicalTripicon} alt="" />
                        <h2>Plan My Medical Trip</h2>
                    </div>
                    <div className="trip-list">
                        <img src={medicineIndia} alt="" />
                        <h2>Do you want <span>Medicines</span> from India?</h2>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HospitalEnquiry
