import { createSlice } from '@reduxjs/toolkit'

export const experianceSlice = createSlice({
  name: 'experiance',
  initialState: {
    value: ''
  },
  reducers: {
    setExperiance: (state,action) => {
      state.value = action.payload
    }
   
  }
})

export const { setExperiance } =  experianceSlice.actions

export default  experianceSlice.reducer