import { createSlice } from '@reduxjs/toolkit'

export const selectedCountrySlice = createSlice({
  name: 'selectedCountry',
  initialState: {
    value: 1
  },
  reducers: {
    updateSelectedCountry: (state,action) => {
      state.value = action.payload
    }
   
  }
})

export const { updateSelectedCountry } = selectedCountrySlice.actions

export default selectedCountrySlice.reducer