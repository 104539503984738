import React from 'react'


import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Getintouch() {

    const settings = useSelector(state => state.settings.value)

    return (
        <div>

            <section>
                <div className="medical-expert main-pd text-center">
                    <h2 className="m-0"> Our world-class medical experts would be happy to assist you.</h2>
                </div>
                <div className="get-in-touch main-pd text-center">
                    <Link to="/contact" className="gettouch t-600">GET IN TOUCH</Link>
                    <h2 style={{color:'white'}}>or call</h2>
                    <h2 className="phoe-number" style={{color:'white'}}><a href={`tel:${settings.site_24_support}`} className='text-white'>{settings.site_24_support}</a></h2>
                </div>
            </section>

        </div>
    )
}

export default Getintouch
