import React, { useState, useEffect } from 'react'


import '../../assets/css/news.scoped.css'
import axios from 'axios'
import apiUrl from '../../services/apiUrl'
import Item from 'antd/lib/list/Item'
import placeHolder from '../../assets/images/placeholder.png'
import { Link } from 'react-router-dom'


function News() {

    const [news, setnews] = useState([])

    var options = { year: 'numeric', month: 'long', day: 'numeric' };

    useEffect(() => {
        getNews()
    }, [])


    const getNews = async () => {
        try {
            var response = await axios.post(apiUrl.getCms, { category_id: 3 })
            if (response?.data?.data?.cms) {
                setnews(response.data.data.cms)
            }
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <div>

            <section>
                <div className="news-section">
                    <div className="row text-center m-0">
                        <div className="tl-contr to-top">
                            <h1>Latest News  Insights</h1>
                        </div>
                    </div>
                    <div className="row main-pd ns-contr">

                        {news[0]?.id && <div className="col-7 position-relative news-first mbb">
                            <img src={news[0]?.image ? `${apiUrl.host}/storage/${news[0]?.image}` : placeHolder} alt="" />
                            <div className="news-content">
                                <Link to={`/news/${news[0]?.id}`}><h1 className='text-dark'>{news[0]?.title}</h1></Link>
                                <p>{new Date(news[0]?.date).toLocaleDateString("en-US", options)}</p>
                            </div>
                        </div>}

                        <div className="col-5 news-sec">
                            {news[1] && <div className="col-12 position-relative mbb">
                                <img src={news[1]?.image ? `${apiUrl.host}/storage/${news[1]?.image}` : placeHolder} alt="" />
                                <div className="news-content">
                                <Link to={`/news/${news[1]?.id}`}><h1 className='text-dark'>{news[1]?.title}</h1></Link>
                                    <p>{new Date(news[1]?.date).toLocaleDateString("en-US", options)}</p>
                                </div>
                            </div>}

                            {news[2] && <div className="col-12 position-relative mbb">
                                <img src={news[2]?.image ? `${apiUrl.host}/storage/${news[2]?.image}` : placeHolder} alt="" />
                                <div className="news-content">
                                <Link to={`/news/${news[2]?.id}`}><h1 className='text-dark'>{news[2]?.title}</h1></Link>
                                    <p>{new Date(news[2]?.date).toLocaleDateString("en-US", options)}</p>
                                </div>
                            </div>}

                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default News
