import axios from 'axios'
import React, {useRef, useState, useEffect } from 'react'
import apiUrl from '../../services/apiUrl'


import { Skeleton } from 'antd'
import 'antd/lib/skeleton/style/index.css'
import '../../assets/css/treatment/treatment.scoped.css'

function TreatmentPlans(props) {
    const [treatmentPlans, settreatmentPlans] = useState([])
    const [isPlanLoading, setisPlanLoading] = useState(true)

    useEffect(() => {
        getTreatmentPlan()
    }, [])

    const getTreatmentPlan = async () => {
        try {
            var response = await axios.post(apiUrl.getTreatmentPlan, { treatment_id: props.id })
            console.log(response.data)
            if (response?.data && response?.data?.data?.tratmentPlans) {
                settreatmentPlans(response.data.data.tratmentPlans)
                setisPlanLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <section>
            <div className="main-pd plans-main-contr">
                <div className="tl-contr float-start w-100">
                    <h1 className="text-start float-start">Treatment Plans</h1>
                </div>
                <Skeleton loading={isPlanLoading}>
                    {treatmentPlans.map(item =>


                        <div className="plans-contr" key={item.id}>
                            <div className="plan-list">
                                <div className="list-plan text-center">
                                    <h1 className="t-600 cnnt">{item.days}</h1>
                                    <h2 className="t-600">Total Days In Country</h2>
                                </div>
                            </div>
                            <div className="plan-list">
                                <div className="list-plan">
                                    <h1>Stay in hospital</h1>
                                    <p>{item.no_of_travelers} No Travelers<br />
                                        {item.inside_hospital} Day in Hospital<br />
                                        {item.outside_hospital} Day outside Hospital</p>
                                </div>
                            </div>



                            <div className="plan-list">
                                <div className="list-plan">
                                    <h1>Success Rate</h1>
                                    <h2>{item.success_rate} of Surgery</h2>
                                    <p>Results may vary from patient to patient, Ask for exact success rate to your medical condition</p>
                                </div>
                            </div>
                            <div className="plan-list">
                                <div className="list-plan text-center">
                                    <h2>Treatment cost starts from </h2>
                                    <h1>USD {item.cost}</h1>
                                    <div className="col-12">
                                        <a href="#" className="cl-one">Worldwide Cost</a>
                                    </div>
                                    <div className="col-12">
                                        <a href="#" className="cl-one">Treatment Planner</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )}

                </Skeleton>


            </div>
        </section>
    )
}

export default TreatmentPlans
