import React from 'react'
import { useSelector } from 'react-redux'

import '../../assets/css/mapsection.scoped.css'


function Mapsection() {

    const settings = useSelector(state => state.settings.value)

    return (
        <div>
            <section>
                <div className="row map-section main-pd">
                    <div className="col-6">
                    {settings?.map_url && <div dangerouslySetInnerHTML={{ __html: `<iframe src=${settings.map_url} width="100%" height="400" style={{ border: 0 }}/>`}} />}
                        
                    </div>
                    <div className="col-6 map-content">
                        <h1>World’s Leading Chain of Doctors  Hospitals </h1>
                        <div className="fes-cn">
                            <p>Best Doctors</p>
                            <p>Best Hospitals</p>
                            <p>Affordable Price</p>
                        </div>
                        <div className="hs-fea">
                            <ul>
                                <li>
                                    <div className="cn-center">
                                        <h1 className="t-700">{settings.countries_count} +</h1>
                                        <p>Countries</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="cn-center">
                                        <h1 className="t-700">{settings.doctors_count} +</h1>
                                        <p>Doctors</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="cn-center">
                                        <h1 className="t-700">{settings.patients_count} +</h1>
                                        <p>Patients</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )

}

export default Mapsection