import React, { useEffect, useState } from 'react'


import add from '../../assets/images/add.png'
import '../../assets/css/doctor/doctorsdetail.scoped.css'
import placeHolder from '../../assets/images/placeholder.png'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { message } from 'antd';
import 'antd/lib/message/style/index.css'
import axios from 'axios'
import apiUrl from '../../services/apiUrl'
import { Link } from 'react-router-dom'

function Doctorenquiry(props) {

    const [submiting, setsubmiting] = useState(false)
    const [consulatationList, setconsulatationList] = useState([])



    useEffect(() => {
        getConsultation()
    }, [])

    const schema = Yup.object({
        email: Yup.string().email('Invalid Email').required('Email Required'),
        phone: Yup.number().transform((currentValue, originalValue) => {
            return originalValue === '' ? null : currentValue;
        })
            .nullable()
            .typeError('Must be a Phone number'),
    })

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const submitEnquiry = async (data, e) => {
        setsubmiting(true)
        try {
            var response = await axios.post(apiUrl.postDoctorEnquiry, {
                doctor_id: props.doctorId,
                name: data.name,
                email: data.email,
                contact: data.phone,
                reason: data.consult,
                seen_doctor_before: data.seenStatus
            })

            if (response.data?.status) {
                setsubmiting(false)
                message.success(response.data?.message || 'Enquiry Recorded Successfully !', 3)
            } else {
                setsubmiting(false)
                message.error('Failed Record Enquiry', 3)
            }
        } catch (error) {
            console.log(error)
            setsubmiting(false)
            message.error('Failed Record Enquiry', 3)
        } finally {
            e.target.reset();
            reset()
        }
    }

    const getConsultation = async () => {
        try {
            var response = await axios.post(apiUrl.getDoctorConsultation, { doctor_id: props.doctorId })
            if (response.data?.data?.treatments) {
                setconsulatationList(response.data.data.treatments)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className="col-4 right-sec">
                <div className="form-contr">
                    <form onSubmit={handleSubmit(submitEnquiry)}>
                        <h2>Book an appointment for free</h2>
                        <label htmlFor="">Your Name</label>
                        <input type="text" placeholder="Enter your name" className="form mb-3" {...register("name")} />
                        <label htmlFor="">Your Email ID</label>
                        <input type="email" placeholder="Enter your email ID" className="form mb-1" {...register("email")} />
                        <div className='errmsg text-dark mb-1'>{errors.email?.message}</div>
                        <label htmlFor="">Your Phone Number</label>
                        <input type="text" placeholder="Enter your phone number" className="form mb-1" {...register("phone")} />
                        <div className='errmsg text-dark mb-1'>{errors.phone?.message} </div>
                        <label htmlFor="">What's the reason for your visit?</label>
                        <select aria-placeholder="Dental Consultation" className="form mb-3" {...register("consult")}>
                            {consulatationList.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                        </select>
                        <label >Has the patient seen this Doctor before?</label>

                        <div className="row">
                            <div className="col-6">
                                <div className="form-check">
                                    <input type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="1" {...register("seenStatus")} />
                                    <label htmlFor="flexRadioDefault1">
                                        Yes
                                    </label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-check">
                                    <input type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="0" checked  {...register("seenStatus")} />
                                    <label htmlFor="flexRadioDefault2">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <button type="submit" value="Continue booking" className="form">
                            {!submiting ? 'Continue booking' : <span className="spinner-border spinner-border-sm" role="status"> </span>}
                        </button>

                    </form>
                </div>

                {!props?.hospials.length && <div className="recent-blog">
                    <div className="adds">
                        <img src={add} alt="" />
                    </div>
                    <h2>Hospital where she/he works</h2>

                    <div className="blog-list">
                        <Link to={`/hospital/${props?.hospials?.id}/`}>
                            <img src={props?.hospials?.image ? `${apiUrl.host}/storage/${props?.hospials?.image}` : placeHolder} alt="" />
                            <h2 className='text-dark'>{props?.hospials?.title || ''} </h2>
                            <div>
                                <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.1698 3.15753C16.2351 1.14277 13.6628 0.0332031 10.9268 0.0332031C8.19075 0.0332031 5.61839 1.14277 3.68375 3.15753C1.74906 5.17233 0.683594 7.85106 0.683594 10.7003C0.683594 16.4642 5.91696 21.2584 8.72853 23.8339C9.11924 24.1919 9.45664 24.501 9.72546 24.7624C10.0622 25.0901 10.4945 25.2539 10.9267 25.2539C11.359 25.2539 11.7912 25.0901 12.1281 24.7624C12.3969 24.5009 12.7343 24.1919 13.125 23.8339C15.9365 21.2583 21.1699 16.4642 21.1699 10.7003C21.1699 7.85106 20.1044 5.17233 18.1698 3.15753ZM12.1879 22.7247C11.7887 23.0905 11.4439 23.4064 11.1596 23.6829C11.029 23.8098 10.8245 23.8099 10.6938 23.6829C10.4096 23.4063 10.0648 23.0904 9.66548 22.7247C7.02226 20.3033 2.10217 15.7961 2.10217 10.7003C2.10217 5.63311 6.0608 1.51064 10.9267 1.51064C15.7925 1.51064 19.7511 5.63311 19.7511 10.7003C19.7512 15.7961 14.8312 20.3033 12.1879 22.7247Z" fill="#E6771B" />
                                    <path d="M10.9203 5.6377C8.43124 5.6377 6.40625 7.74644 6.40625 10.3385C6.40625 12.9306 8.43124 15.0393 10.9203 15.0393C13.4094 15.0393 15.4343 12.9306 15.4343 10.3385C15.4343 7.74644 13.4094 5.6377 10.9203 5.6377ZM10.9203 13.5619C9.21351 13.5619 7.82492 12.1159 7.82492 10.3385C7.82492 8.56109 9.21351 7.11503 10.9203 7.11503C12.6271 7.11503 14.0156 8.56109 14.0156 10.3385C14.0156 12.1159 12.6271 13.5619 10.9203 13.5619Z" fill="#E6771B" />
                                </svg>
                                <span className='text-dark'>{props?.hospials?.address || ''}</span>
                                </div>
                        </Link>

                    </div>
                </div>}
            </div>
        </>
    )
}

export default Doctorenquiry