import { createSlice } from '@reduxjs/toolkit'

export const selectedCategorySlice = createSlice({
  name: 'selectedCategory',
  initialState: {
    value: {id:0}
  },
  reducers: {
    updateSelectedCategory: (state,action) => {
      state.value = action.payload
    }
   
  }
})

export const { updateSelectedCategory } = selectedCategorySlice.actions

export default selectedCategorySlice.reducer