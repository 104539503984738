import { createSlice } from '@reduxjs/toolkit'

export const selectedCitySlice = createSlice({
  name: 'selectedCity',
  initialState: {
    value: 0
  },
  reducers: {
    setSelectedCity: (state,action) => {
      state.value = action.payload
    }
   
  }
})

export const { setSelectedCity } = selectedCitySlice.actions

export default selectedCitySlice.reducer