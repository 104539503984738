import React, { useState } from 'react'

import Hero from '../components/common/hero'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import apiUrl from '../services/apiUrl';
import { message } from 'antd';
import 'antd/lib/message/style/index.css'
import '../assets/css/business/business.scoped.css'

import lsBg from '../assets/images/ls-bg.png'
import medicalAssistance from '../assets/images/Medical-Assistance-Globally.png'
import healthInsurance from '../assets/images/Health-Insurance.png'
import welnessAssistance from '../assets/images/wellness-assistance.png'
import protectImg from '../assets/images/protect-img.png'

import Benefits1 from '../assets/images/Benefits1'
import Benefits2 from '../assets/images/Benefits2'
import Benefits3 from '../assets/images/Benefits3'
import Benefits4 from '../assets/images/Benefits4'
import Benefits5 from '../assets/images/Benefits5'

import nsArrow from '../assets/images/ns-arrow.svg'
import brand from '../assets/images/brand.png'
import one from '../assets/images/1.png'



function BusinessPage() {

    const [submitiing, setsubmitiing] = useState(false)
    const [businessDocs, setbusinessDocs] = useState('')

    const schema = Yup.object({
        email: Yup.string().email('Invalid Email').required('Email Required'),
        contactNumber: Yup.number().transform((currentValue, originalValue) => {
            return originalValue === '' ? null : currentValue;
        })
            .nullable()
            .typeError('Must be a number'),
    })

    const { register, handleSubmit,reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const submitEnquiry = async (data,e) => {
        setsubmitiing(true)
        try {
            var fdata = new FormData();
            fdata.append('organization', data.hospitalName)
            fdata.append('email', data.email)
            fdata.append('contact', data.contactNumber)
            fdata.append('location', data.location)
            fdata.append('file', businessDocs)

            var response = await axios.post(apiUrl.setBizzEnquiry, fdata, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })

            if (response.data.status) {
                message.success(response.data.message || 'Enguiry Recorded Successfully', 3)
                setsubmitiing(false)
            } else {
                setsubmitiing(false)
                message.error('Failed Record Engquiry', 3)
            }
        } catch (error) {
            console.log(error)
            setsubmitiing(false)
            message.error('Failed Record Engquiry', 3)
        }finally{
            e.target.reset();
            reset()
            setbusinessDocs('')
        }
    }

    return (
        <>
            <Hero />

            <section>
                <div className="main-pd position-relative">
                    <img src={lsBg} alt="" className="fm-bg" />
                    <div className="row forms-cn">
                        <div className="col-6 bn-content">
                            <h1>Sagittis id dapibus massa pellentesque consectetur in sed.</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                        <div className="col-6 form-main"> 
                            <div className="form-contr">
                                <form onSubmit={handleSubmit(submitEnquiry)}>
                                    <label>Name</label>
                                    <input type="text" placeholder="Enter Your Name" className="form" {...register("hospitalName")} />
                                    <label className="email-label">Email</label>
                                    <input type="text" placeholder="Enter  Email" className="form" {...register("email", { required: true, message: "You must enter your name" })} />
                                    <div className='errmsg'>{errors.email?.message}</div>

                                    <label>Contact Number</label>
                                    <input type="text" placeholder="Enter Number" className="form" {...register("contactNumber")} />
                                    <div className='errmsg'>{errors.contactNumber?.message}</div>
                                    <label>Location</label>
                                    <input type="text"  placeholder="Enter Location" className="form" {...register("location")} />
                                    <div className="row">
                                        <div className="col-6">
                                            <input type="file" className="inputfile inputfile-1" id='file-1' accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            onChange={(e) => {setbusinessDocs(e?.target?.files[0] || '')}} />
                                            <label htmlFor="file-1" className="form mnt">
                                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.1875 9.625V12.25H1.875V9.625H0V13.125C0 13.6089 0.42 14 0.9375 14H13.125C13.6434 14 14.0625 13.6089 14.0625 13.125V9.625H12.1875Z" fill="#213B4D" />
                                                    <path d="M10.6651 3.64963L7.38381 0.149625C7.29475 0.055125 7.16631 0 7.03131 0C6.89631 0 6.76787 0.055125 6.67881 0.149625L3.39756 3.64963C3.27662 3.77913 3.24662 3.962 3.32349 4.11862C3.39943 4.27437 3.56631 4.375 3.75006 4.375H5.62506V10.0625C5.62506 10.304 5.83506 10.5 6.09381 10.5H7.96881C8.22756 10.5 8.43756 10.304 8.43756 10.0625V4.375H10.3126C10.4963 4.375 10.6632 4.27525 10.7391 4.11862C10.8151 3.962 10.7869 3.77825 10.6651 3.64963Z" fill="#213B4D" />
                                                </svg><span>{businessDocs.name || 'Attachment'}</span>
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            {submitiing ? <button type="submit" className="form mnt submit1">
                                                <span className="spinner-border spinner-border-sm" role="status"> </span> </button>
                                                : <button type="submit" className="form mnt submit1">Send Details</button>}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="main-pd row list-main-show">
                    <div className="col-4">
                        <div className="show-list">
                            <div className="img-icon">
                                <img src={medicalAssistance} alt="" />
                            </div>
                            <h2>Medical Assistance Globally</h2>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="show-list">
                            <div className="img-icon">
                                <img src={healthInsurance} alt="" />
                            </div>
                            <h2>Health Insurance (TPA)</h2>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="show-list">
                            <div className="img-icon">
                                <img src={welnessAssistance} alt="" />
                            </div>
                            <h2>Wellness Assistance</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="row main-pd m-0">
                    <div className="row m-0 protect-cn p-0">
                        <div className="col-7 content-cn">
                            <h1>Protect your staff’s health and look after your business</h1>
                            <p>Getting comapny private medical insurance is weasier than you think. Freedom health Insurance is here to hep you set everything up and offer the right level of cover fotr your employees so that you have a healthier wok force. our corporate health insurance plans can also fit  around your specific business goals and budjet. To discuss your situation and get a corporate health insurance quote, simply contact your insurance broker or request a callback below and we’ll be in touch shortly.</p>
                        </div>
                        <div className="col-5 p-0">
                            <img src={protectImg} alt="" />
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="row">
                    <div className="tl-contr float-start w-100">
                        <div className="cvbn">
                            <h1>Benefits of Corporate tie-ups with us</h1>
                        </div>
                    </div>
                    <div className="im-contr">
                        <div className="im1">
                            <img src={Benefits1} alt="" />
                            <h1>Best Health Package With Best Price</h1>
                        </div>
                        <div className="im2">
                            <img src={Benefits2} alt="" />
                            <h1>Dedicated Case Manage</h1>
                        </div>
                        <div className="im3">
                            <img src={Benefits3} alt="" />
                            <h1>Fast Health Insurance Clearance</h1>
                        </div>
                        <div className="im4">
                            <img src={Benefits4} alt="" />
                            <h1>Corporate Wellness Programs</h1>
                        </div>
                        <div className="im5">
                            <img src={Benefits5} alt="" />
                            <h1>Assistance In & Outside The Hospitals</h1>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="row work-contr main-pd">
                    <div className="tl-contr w-100 text-center">
                        <h1>How it works</h1>
                        <p>Take these three simple steps and become our partner!</p>
                    </div>
                    <div className="row m-0">
                        <div className="col-4">
                            <div className="works">
                                <h1 className="t-600">1</h1>
                                <h2 className="t-600">APPLY ONLINE</h2>
                                <p>Tincidunt velit dui morbi non vitae ultrices elementum vitae.</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="works">
                                <img src={nsArrow} alt="" className="ns-arrow1" />
                                <h1 className="t-600">2</h1>
                                <h2 className="t-600">We’ll be in touch</h2>
                                <p>Tincidunt velit dui morbi non vitae ultrices elementum vitae.</p>
                                <img src={nsArrow} alt="" className="ns-arrow2" />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="works">
                                <h1 className="t-600">3</h1>
                                <h2 className="t-600">Let’s get started</h2>
                                <p>Tincidunt velit dui morbi non vitae ultrices elementum vitae.</p>
                            </div>
                        </div>
                        <div className="col-12">
                            <a href="#" className="tie-up t-600">TIE-UP NOW</a>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="col-12 main-pd">
                    <div className="tl-contr w-100 m-100 text-center">
                        <h1>Meet our Partners</h1>
                        <p>Our partners span the globe and represent a diverse range of organization and business. We work together to build thriving communities.</p>
                    </div>
                    <div className="row top-brand m-0">
                        <div className="col-3">
                            <img src={brand} alt="" />
                        </div>

                        <div className="col-3">
                            <img src={brand} alt="" />
                        </div>

                        <div className="col-3">
                            <img src={brand} alt="" />
                        </div>

                        <div className="col-3">
                            <img src={brand} alt="" />
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="row main-pd m-0">
                    <div className="tl-contr cl1 w-100 p-0">
                        <h1 className="text-start float-start">Here’s what our partners say</h1>
                    </div>
                    <div className="row partners-say text-center m-0">
                        <div className="col-4">
                            <div className="say-list">
                                <img src={one} alt="" />
                                <h3>Magnuessan Peterson</h3>
                                <span>Lorem ipsumdummy</span>
                                <p>Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="say-list">
                                <img src={one} alt="" />
                                <h3>Magnuessan Peterson</h3>
                                <span>Lorem ipsumdummy</span>
                                <p>Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="say-list">
                                <img src={one} alt="" />
                                <h3>Magnuessan Peterson</h3>
                                <span>Lorem ipsumdummy</span>
                                <p>Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default BusinessPage
