import { createSlice } from '@reduxjs/toolkit'

export const stateListSlice = createSlice({
  name: 'statelist',
  initialState: {
    value: []
  },
  reducers: {
    setStateList: (state,action) => {
      state.value = action.payload
    }
   
  }
})

export const { setStateList } = stateListSlice.actions

export default stateListSlice.reducer