import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals'
import './assets/fonts/TitilliumWeb-Bold.ttf'
import './assets/fonts/TitilliumWeb-Regular.ttf'
import './assets/fonts/TitilliumWeb-SemiBold.ttf'
import './assets/fonts/WorkSans-Medium.ttf'

import store from './store/store'
import { Provider } from 'react-redux'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
