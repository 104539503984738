import { Modal, Button, Form } from 'react-bootstrap';

import apiUrl from '../../services/apiUrl';
import axios from 'axios';



import React, { useState } from 'react'

function HospitalBookPopup({ showPopup, hidePopup }) {
    const [first, setfirst] = useState('')
    const [email, setemail] = useState('')
    const [phone, setphone] = useState('')
    const [message, setmessage] = useState('')
    const [validated, setvalidated] = useState(false)
    const [engError, setengError] = useState(false)


    const submitForm = async () => {
        try {

            var response = await axios.post(apiUrl.setCommonEnquiry, {
                email: email,
                contact: phone,
                name: first,
                message: message
            })

            if (response?.data?.status) {
                hidePopup(false)
            }

        } catch (error) {
            console.log(error)
            setengError(true)

        }
    }



    const handleSubmit = (event) => {
        setengError(false)
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        submitForm()
        setvalidated(true);
    };







    return (


        <>
            <Modal
                show={showPopup}
                keyboard={false}
                centered
                size="md"
                onHide={() => hidePopup()}
            >
                <Form noValidate validated={validated} onSubmit={handleSubmit} >
                    <Modal.Header closeButton style={{ border: 0 }}>
                        <Modal.Title>Book Hospital</Modal.Title>
                    </Modal.Header>

                    {engError && <span className='text-danger' style={{ margin: "auto", display: "table" }}>Failed to record Enquiry</span>}

                    <Modal.Body>

                        <div></div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Name" value={first} onChange={(e) => setfirst(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control required type="email" placeholder="Enter Email" value={email} onChange={(e) => setemail(e.target.value)} />
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Diseases</Form.Label>


                            <Form.Select aria-label="Default select example">
                                <option>Choose Diseases</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Form.Group>



                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} value={message} onChange={(e) => setmessage(e.target.value)} />
                        </Form.Group>


                        <input type="file" id="file-1"  className="inputfile inputfile-1"
                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" hidden />
                        <label htmlFor="file-1" className="form mnt">
                            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.1875 9.625V12.25H1.875V9.625H0V13.125C0 13.6089 0.42 14 0.9375 14H13.125C13.6434 14 14.0625 13.6089 14.0625 13.125V9.625H12.1875Z"
                                    fill="#213B4D" />
                                <path
                                    d="M10.6651 3.64963L7.38381 0.149625C7.29475 0.055125 7.16631 0 7.03131 0C6.89631 0 6.76787 0.055125 6.67881 0.149625L3.39756 3.64963C3.27662 3.77913 3.24662 3.962 3.32349 4.11862C3.39943 4.27437 3.56631 4.375 3.75006 4.375H5.62506V10.0625C5.62506 10.304 5.83506 10.5 6.09381 10.5H7.96881C8.22756 10.5 8.43756 10.304 8.43756 10.0625V4.375H10.3126C10.4963 4.375 10.6632 4.27525 10.7391 4.11862C10.8151 3.962 10.7869 3.77825 10.6651 3.64963Z"
                                    fill="#213B4D" />
                            </svg><span>{ 'Choose a file…'}</span>
                        </label>






                    </Modal.Body>
                    <Modal.Footer style={{ border: 0 }}>
                        <Button variant="secondary" onClick={() => hidePopup()}>
                            Close
                        </Button>
                        <Button style={{ backgroundColor: '#E6771B', borderColor: '#E6771B' }} type="submit" >Submit Enquiry</Button>
                    </Modal.Footer>
                </Form>
                
            </Modal>


        </>



    )
}

export default HospitalBookPopup


