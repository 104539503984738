
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import axios from 'axios'
import apiUrl from '../../services/apiUrl'
import { useDispatch } from 'react-redux'
import { setSelectedDept } from '../../features/search/selectedDept'
import { setsearchText } from '../../features/search/searchTextSlice'
import { Link } from 'react-router-dom'
import SimpleBar from 'simplebar-react';
import { useLocation } from 'react-router-dom'


import '../../assets/css/autosuggetion/hospitalSearch.scoped.css'
import HandleOutsideClick from "react-outside-click-event";



function HospitalSearchBox(props) {
    // const selectedDept = useSelector(state => state.selectedDept.value)
    // const [departmentList, setdepartmentList] = useState([])
    const dispatch = useDispatch()
    let location = useLocation()

    const searchText = useSelector(state => state.searchText.value)
    const [hospitalSuggetion, sethospitalSuggetion] = useState([])
    const [tempHospitalSuggetion, settempHospitalSuggetion] = useState([])
    const selectedCountry = useSelector(state => state.slectedCountry.value)
    const [stateList, setstateList] = useState([])
    const [selectedState, setselectedState] = useState(5)
    const [showSuggetion, setshowSuggetion] = useState(false)


    useEffect(() => {
        getStatesByCountry()
    }, [])

    useEffect(() => {
        setshowSuggetion(false)
    }, [location]);



    const filterState = (data) => {
        setselectedState(data)
        var suggetion = tempHospitalSuggetion.filter(item => item.city_id === selectedState)
        sethospitalSuggetion(suggetion)
    }


    // const getDepartmentList = async () => {
    //     try {
    //         var response = await axios.get(apiUrl.getDepartmentList)
    //         if (response?.data?.status && response?.data?.data?.departments) {
    //             setdepartmentList(response.data.data.departments)
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }


    const getStatesByCountry = async () => {
        try {
            var response = await axios.post(apiUrl.getStateListByCountry, { country_id: selectedCountry })
            if (response?.data?.data.countryBasedState) {
                setstateList(response.data.data.countryBasedState)
            }
        } catch (error) {
            console.log(error)

        }
    }

    const getHospitalSuggetion = async () => {
        setshowSuggetion(true)
        try {
            var response = await axios.post(apiUrl.getHospitalSuggetion, { search_text: searchText })
            if (response?.data?.hospital) {
                console.log(response.data.data)
                sethospitalSuggetion(response.data.hospital)
                settempHospitalSuggetion(response.data.hospital)
            }
            console.log(response.data, 'hos')
        } catch (error) {
            console.log(error)
        }
    }

    const hospitalSuggetionController = (e) => {
        dispatch(setsearchText(e.target.value))
        getHospitalSuggetion()
    }


    return (
        <>
            <HandleOutsideClick
                onOutsideClick={() => {
                    setshowSuggetion(false)
                }}
            >
                <input type="search" placeholder="Search Here!" value={searchText}
                    onChange={(e) => hospitalSuggetionController(e)}
                />

                {hospitalSuggetion.length > 0 && showSuggetion && <section>
                    <div className="row col-12 hospital m-0">


                        <div className="hospital-left">
                            <h2 className="tip-tl">Categories</h2>
                            <ul>
                                <SimpleBar forceVisible="y" style={{ maxHeight: 'auto' }}>
                                    <div className="content" id="simple-bar">
                                        {/* <li className="active">Banglore</li> */}
                                        {stateList.map(item => <li key={item.id} className={item.id === selectedState && 'active'} onClick={() => filterState(item?.id)} style={{ cursor: 'pointer' }} >{item.name}</li>)}

                                    </div>
                                </SimpleBar>
                            </ul>
                        </div>

                        <div className="hospital-right">

                            {hospitalSuggetion.map(item => <div className="hospital-list" key={item.id}>
                                <div className="hospital-img">
                                    <img src={`${apiUrl.host}/storage/${item?.image}`} alt="" />
                                </div>
                                <div className="hs-content">
                                    <div className="hs-detail">
                                        <h2>{item?.title}</h2>
                                        <div className="content">{item?.address}</div>
                                        <div className="star">

                                            {[1, 2, 3, 4, 5].map((newitem, i) => <i key={i} className={Number(item?.rating || 0) >= i + 1 ? "fas fa-star active" : "fas fa-star"}></i>)}
                                        </div>
                                        {item?.verified_badge === 1 && <p>MMA Verified</p>}
                                    </div>
                                    <div className="links text-start">
                                        <Link to={`/hospital/${item.id}/`} className="btcn1">View Details</Link>

                                        <Link to={`/hospital/${item.id}/`} className="btcn">Book Appointment</Link>
                                    </div>
                                </div>
                            </div>)}



                        </div>

                    </div>
                </section>}

            </HandleOutsideClick>

        </>

        // <div className="row">
        //     <input type="text" aria-label="First name" className="form-control" />
        //     <input type="text" aria-label="Last name" className="form-control" />


        //     <div className="col m-0">
        //         <input type="search" className="form-control m-0 custom-input-box" placeholder='Search Here'
        //         value={props.value}
        //         onChange={(e) => { dispatch(setsearchText(e.target.value)) }}/>
        //     </div>

        //     <div className="col-md-4 m-0">
        //         <select className="form-select form-select-lg mb-3 custom-select" style={{ height: '100%' }}
        //         onChange={(e) => { dispatch(setSelectedDept(e.target.value)) }}>
        //             <option selected hidden>Choose Department</option>
        //             {departmentList.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}

        //         </select>
        //     </div>
        // </div>
    )
}

export default HospitalSearchBox
