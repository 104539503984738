import { Modal, Button, Form } from 'react-bootstrap';

import apiUrl from '../../services/apiUrl';
import axios from 'axios';
import { useForm } from 'react-hook-form'



import React, { useState } from 'react'

function EnquiryModal({ showPopup, hidePopup }) {

    // const { register, handleSubmit, reset,formState: { errors } } = useForm({})

    const [first, setfirst] = useState('')
    const [email, setemail] = useState('')
    const [phone, setphone] = useState('')
    const [message, setmessage] = useState('')
    const [validated, setvalidated] = useState(false)
    const [engError, setengError] = useState(false)


    const submitForm = async () => {
        try {

            var response = await axios.post(apiUrl.setCommonEnquiry, {
                email: email,
                contact: phone,
                name: first,
                message: message
            })

            if (response?.data?.status) {
                hidePopup(false)
            }

        } catch (error) {
            console.log(error)
            setengError(true)

        }
    }



    const handleSubmit = (event) => {
        setengError(false)
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        submitForm()
        setvalidated(true);
    };









    return (
        <>
            <Modal
                show={showPopup}
                keyboard={false}
                centered
                size="md"
                onHide={() => hidePopup()}
            >
                <Form noValidate validated={validated} onSubmit={handleSubmit} >
                    <Modal.Header closeButton style={{ border: 0 }}>
                        <Modal.Title>Enquiry Form</Modal.Title>
                    </Modal.Header>
                   
                   {engError && <span className='text-danger' style={{margin:"auto",display:"table"}}>Failed to record Enquiry</span>}

                    <Modal.Body>

                        <div></div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Name" value={first} onChange={(e) => setfirst(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control required type="email" placeholder="Enter Email" value={email} onChange={(e) => setemail(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="phone" placeholder="Enter Email" value={phone} onChange={(e) => setphone(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} value={message} onChange={(e) => setmessage(e.target.value)} />
                        </Form.Group>






                    </Modal.Body>
                    <Modal.Footer style={{ border: 0 }}>
                        <Button variant="secondary" onClick={() => hidePopup()}>
                            Close
                        </Button>
                        <Button style={{ backgroundColor: '#E6771B', borderColor: '#E6771B' }} type="submit" >Submit Enquiry</Button>
                    </Modal.Footer>
                </Form>
            </Modal>


        </>
    )
}

export default React.memo(EnquiryModal)