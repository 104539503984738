import React, { useState, useEffect } from 'react'


import '../assets/css/hospital/hospitalDetails.scoped.css'
import { Link, useParams } from 'react-router-dom'

import locationIcon from '../assets/images/location.svg'
import mmaIcon from '../assets/images/mma-certified.svg'
import jciIcon from '../assets/images/jci.png'
import nabhIcon from '../assets/images/nabh.png'
import starFill from '../assets/images/star-fill.svg'
import hospitalPlaceIcon from '../assets/images/hospital-place-icon.svg'
import map from '../assets/images/map.png'
import Wheather from '../assets/images/Wheather.png'
import Hero from '../components/common/hero'
import placeHolder from '../assets/images/placeholder.png'
import PackagesList from '../components/common/packagesList'
import PackagesInDetails from '../components/common/packagesInDetails'

import HospitalEnquiry from '../components/enquiry/hospitalenquiry'
import axios from 'axios'
import apiUrl from '../services/apiUrl'
import parse from 'html-react-parser'
import Slider from "react-slick";
import Faq from '../components/common/faq'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function HospitalDetails() {
    const { hospitalId } = useParams()
    const [hospitalDetails, sethospitalDetails] = useState({ bannerContent: [] })
    const [tabOne, settabOne] = useState(1)
    const [tabTwo, settabTwo] = useState(1)
    const [hospitalDoctor, sethospitalDoctor] = useState([])
    const [similiarHospital, setsimiliarHospital] = useState([])
    const [hospitalDept, sethospitalDept] = useState([])
    const [successStories, setsuccessStories] = useState([])
    const [telemedineDoctor, settelemedineDoctor] = useState([])
    const [specialFeatures, setspecialFeatures] = useState([])
    const [hospitalFaq, sethospitalFaq] = useState([])

    useEffect(() => {
        getHospitalDetails()
        getHospitalDoctor()
        getSimiliarHospital()
        getHospitalDepartment()
        getSuccessStories()
        getTelemedicineDoctor()
        getSpecialFeatures()
        getHospitalFaq()
    }, [hospitalId])

    const getHospitalDetails = async () => {
        try {
            var response = await axios.post(apiUrl.getHospitalDetails, { hospital_id: hospitalId })
            if (response?.data?.data?.hospital) {
                sethospitalDetails(response.data.data.hospital)
            }
        } catch (error) {
            console.error(error)

        }
    }

    const getHospitalDoctor = async () => {
        try {
            var response = await axios.post(apiUrl.getHospitalDoctor, { hospital_id: hospitalId })
            if (response?.data?.data?.doctors) {
                sethospitalDoctor(response.data.data.doctors)
            }
        } catch (error) {
            console.error(error)

        }
    }

    const getSimiliarHospital = async () => {
        try {
            var response = await axios.post(apiUrl.getHospitalSimiliarHospital, { hospital_id: hospitalId })
            if (response?.data?.data?.similarHospitals) {
                setsimiliarHospital(response.data.data.similarHospitals)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getSuccessStories = async () => {
        try {
            var response = await axios.post(apiUrl.getHospitalSuccessStories, { hospital_id: hospitalId })
            if (response?.data?.data?.successStories) {
                setsuccessStories(response.data.data.successStories)
            }
        } catch (error) {
            console.error(error)
        }
    }


    const getHospitalDepartment = async () => {
        try {
            var response = await axios.post(apiUrl.getHospitalDepartment, { hospital_id: hospitalId })
            if (response?.data?.data?.dept) {
                sethospitalDept(response.data.data.dept)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getTelemedicineDoctor = async () => {
        try {
            var response = await axios.post(apiUrl.getHospitalTelemedicineDoctor, { hospital_id: hospitalId })
            if (response?.data?.data?.telemedicneDoctor) {
                settelemedineDoctor(response.data.data.telemedicneDoctor)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getSpecialFeatures = async () => {
        try {
            var response = await axios.post(apiUrl.getHospitalFeatures, { hospital_id: hospitalId })
            if (response?.data?.data?.specialFeature) {
                setspecialFeatures(response.data.data.specialFeature)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getHospitalFaq = async()=>{
        try {
            
            var response = await axios.post(apiUrl.getHospitalFaq,{hospital_id:hospitalId})
            if(response?.data?.data?.hospitalFaq){
                sethospitalFaq(response.data.data.hospitalFaq)
            }
            

        } catch (error) {
            
        }
    }

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            Previous
        </button>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            Next
        </button>
    );


    const similiarHospitalsettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrow: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,

        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrow: true,
    };

    return (
        <>
            <Hero />
            <section className="banner-slides">
                <Slider {...settings}>
                    {hospitalDetails.bannerContent.map(item =>
                        <div key={item.id} className="row bn-sec-main">
                            <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', width: '100%' }}>
                                <div className="bn-one">
                                    <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" height="500" />
                                </div>
                                <div className="bn-two">
                                    <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" />
                                    <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" />
                                </div>
                            </div>
                        </div>)}




                </Slider>
            </section>

            <section>
                <div className="main-pd">
                    <div className="row book-contr">
                        <div className="col-4 p-0 book-im bks-cn">
                            <img src={hospitalDetails?.image ? `${apiUrl.host}/storage/${hospitalDetails?.image || ''}` : placeHolder} alt="" />
                        </div>
                        <div className="col-4 p-0 book-ln bks-cn">
                            {hospitalDetails?.title && <h1 className="t-600">{hospitalDetails?.title || ''}, {hospitalDetails.city_name || ''} </h1>}
                            {hospitalDetails?.address && <p><img src={locationIcon} alt="" /> {hospitalDetails?.address || ''}</p>}
                            {hospitalDetails?.verified_badge === 1 && <h2><img src={mmaIcon} alt="" /> MMA Certified</h2>}
                        </div>
                        <div className="col-4 p-0 bks-cn">
                            <a href="#booknow" className="book-bttn">Book Appointment</a>
                            <div className="row jci-nabh">

                                {parse(`${hospitalDetails?.quality_approved_certificate || ''}`)}
                                {/* <div className="jci float-start">
                                    <img src={jciIcon} alt="" />
                                    <h1 className="t-600">JCI</h1>
                                </div>
                                <div className="nabh float-end">
                                    <img src={nabhIcon} alt="" />
                                    <h1 className="t-600">NABH</h1>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="treatment-section">
                <div className="row main-pd">
                    <div className="col-8 left-hs">
                        <section>
                            <div className="row m-0">
                                <ul className="mn-head">
                                    <li className={tabOne === 1 && 'active'} onClick={() => { settabOne(1) }}>Description</li>
                                    <li className={tabOne === 2 && 'active'} onClick={() => { settabOne(2) }}>Credential</li>
                                    <li className={tabOne === 3 && 'active'} onClick={() => { settabOne(3) }}>Success Stories</li>
                                </ul>
                                <div className="cn-section">
                                    {tabOne === 1 && <>{parse(`${hospitalDetails?.content || 'NO Data to Show!'}`)}</>}
                                    {tabOne === 2 && <>{parse(`${hospitalDetails?.credintials || 'NO Data to Show!'}`)}</>}
                                    {tabOne === 3 && <>

                                        <section>
                                            <div className="col-12 text-center testi-contr">
                                                {/* <div className="tl-contr float-start w-100">
                                                    <h1 className="text-start float-start">Success Stories</h1>
                                                </div> */}
                                                <div className="row testimonial">

                                                    {successStories.map(item =>
                                                        <div key={item.id} className="col-12">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" />
                                                                    <h2>{item?.auther || ''}</h2>
                                                                    <p>{parse(`${item?.content || ''}`)}</p>
                                                                </div>

                                                                <div className="col-6">
                                                                    <iframe width="100%" height="220" src={item.link || item.file || ''}
                                                                        title="YouTube video player" frameborder="0"
                                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                        allowFullScreen></iframe>
                                                                    <p>{parse(`${item?.meta_description || 'No Data To show'}`)}</p>
                                                                </div>
                                                            </div>
                                                        </div>)}




                                                </div>
                                            </div>
                                        </section>


                                    </>}
                                </div>

                                <ul className="mn-head">
                                    <li className={tabTwo === 1 && 'active'} onClick={() => { settabTwo(1) }}>Doctors</li>
                                    <li className={tabTwo === 2 && 'active'} onClick={() => { settabTwo(2) }}>Doctors for telemedicine</li>
                                    <li className={tabTwo === 3 && 'active'} onClick={() => { settabTwo(3) }}>Departments</li>
                                </ul>
                                <div className="row doctors-listing-section m-0">
                                    {tabTwo === 1 && <>
                                        {
                                            hospitalDoctor.map(item =>
                                                <div key={item.id} className="col-6 hs-cll-main">
                                                    <div className="doctors-list">
                                                        <div className="doctor-img">
                                                            <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" />
                                                        </div>
                                                        <div className="hs-cll">
                                                            <h2 className="t-600">{item?.name|| ''} </h2>
                                                            <p>{item?.department || ''}</p>
                                                            <p>{item?.country_name || ''}</p>
                                                            <p className="star-c"><img src={starFill} alt="" /> {item?.rating} </p>
                                                        </div>
                                                        <div className="links">
                                                            <Link to={`/doctor/${item.id}`} className="btcn t-600">Book Now </Link>
                                                            <Link to={`/doctor/${item.id}`} className="btcn1 t-600">View Profile</Link>
                                                        </div>
                                                    </div>

                                                </div>)

                                        }
                                        {!hospitalDoctor.length && <span>No Data to Show!</span>}

                                    </>}

                                    {tabTwo === 2 && <>
                                        {
                                            telemedineDoctor.map(item =>
                                                <div key={item.id} className="col-6">
                                                    <div className="doctors-list">
                                                        <div className="doctor-img">
                                                            <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" />
                                                        </div>
                                                        <h2 className="t-600">{item.name} </h2>
                                                        <p>Dentist</p>
                                                        <p>New York, NY</p>
                                                        <p className="star-c"><img src={starFill} alt="" /> {item.rating} </p>
                                                        <div className="links">
                                                            <Link to={`/doctor/${item.id}`} className="btcn t-600">Book Now </Link>
                                                            <Link to={`/doctor/${item.id}`} className="btcn1 t-600">View Profile</Link>
                                                        </div>
                                                    </div>
                                                </div>)}
                                        {!telemedineDoctor.length && <span>No data to show </span>}

                                    </>}

                                    {tabTwo === 3 && <>
                                        {
                                            hospitalDept.map(item =>
                                                <div key={item.id} className="col-6">
                                                    <div className="doctors-list">
                                                        <div className="doctor-img">
                                                            <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" />
                                                        </div>
                                                        <h2 className="t-600 mt-4">{item?.name || ''} </h2>
                                                    </div>
                                                </div>)
                                        }
                                        {!hospitalDept.length && <span>No data to show </span>}

                                    </>}

                                </div>

                                <div className="tl-contr w-100 p-0 sf-cn">
                                    <h1 className="text-start float-start">Special Features</h1>
                                </div>
                                <ul className="core-contr p-0">
                                    {specialFeatures.map(item =>
                                        <li key={item.id}>
                                            <h2> <img src={item?.logo ? `${apiUrl.host}/storage/${item.logo}` : placeHolder} alt="" height="70" width="70" /> {item?.title || ''}</h2>
                                        </li>)}

                                </ul>
                                {!specialFeatures.length && <span>No data to show </span>}

                            </div>

                        </section>

                        <PackagesInDetails/>

                        {/* <div className="tl-contr float-start w-100 pph-cn">
                            <h1 className="row text-start float-start m-0">Popular Packages at this Hospital</h1>
                        </div>

                        <div className="row packages">
                            <div className="col-6">
                                <PackagesList />
                            </div>

                            <div className="col-6">
                                <PackagesList />
                            </div>
                        </div> */}


                        <section>
                            <div className="row">
                                <div className="tl-contr float-start w-100 nmbb">
                                    <h1 className="text-start float-start">What is nearby</h1>
                                </div>

                                <div className="search-contr">
                                    <p>Lorem Ipsum Simply Text Dummy</p>
                                    <input type="search" placeholder="Find Distance from a Capital" />
                                </div>
                                <ul className="mn-head">
                                    <li className="active">Places to visit</li>
                                    <li>Restaurant</li>
                                    <li>Transport</li>
                                    <li>Shopping Mall</li>
                                </ul>

                                <div className="row m-0 map-contr">
                                    <div className="col-6 p-0">
                                        <p>Lorem Ipsum Simply Text Dummy</p>
                                        <p>Lorem Ipsum Simply Text Dummy</p>
                                        <p>Lorem Ipsum Simply Text Dummy</p>
                                    </div>
                                    <div className="col-6 p-0">
                                        <img src={map} alt="" />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section>
                            <div className="row wheather-contr">
                                <div className="tl-contr float-start w-100">
                                    <h1 className="text-start float-start">Wheather Details</h1>
                                    <select name="" id="">
                                        <option value="">Delhi</option>
                                    </select>
                                </div>
                                <div className="col-2 wheather-list">
                                    <h1>26th March</h1>
                                    <img src={Wheather} alt="" />
                                    <h2>41 °C</h2>
                                    <h3>26 °C</h3>
                                </div>
                                <div className="col-2 wheather-list">
                                    <h1>26th March</h1>
                                    <img src={Wheather} alt="" />
                                    <h2>41 °C</h2>
                                    <h3>26 °C</h3>
                                </div>
                                <div className="col-2 wheather-list">
                                    <h1>26th March</h1>
                                    <img src={Wheather} alt="" />
                                    <h2>41 °C</h2>
                                    <h3>26 °C</h3>
                                </div>
                                <div className="col-2 wheather-list">
                                    <h1>26th March</h1>
                                    <img src={Wheather} alt="" />
                                    <h2>41 °C</h2>
                                    <h3>26 °C</h3>
                                </div>
                                <div className="col-2 wheather-list">
                                    <h1>26th March</h1>
                                    <img src={Wheather} alt="" />
                                    <h2>41 °C</h2>
                                    <h3>26 °C</h3>
                                </div>
                                <div className="col-2 wheather-list">
                                    <h1>26th March</h1>
                                    <img src={Wheather} alt="" />
                                    <h2>41 °C</h2>
                                    <h3>26 °C</h3>
                                </div>
                            </div>
                        </section>

                        <section>
                            <div className="col-12 best-doctor-contr text-center best-hh nxe-contr">
                                <div className="tl-contr float-start w-100 mll-mb">
                                    <h1 className="text-start float-start">Similar Hospitals</h1>
                                </div>
                                <div className="row text-start">

                                    <Slider {...similiarHospitalsettings}>

                                        {similiarHospital.map(item =>
                                            <div key={item.id} className="col-4 item">
                                                <Link to={`/hospital/${item.id}`}>
                                                    <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} height="240" alt="" />
                                                    <h2 className='text-dark'>{item.title}</h2>
                                                    {item?.address && <h2 className="inline text-dark"><img src={hospitalPlaceIcon} alt="" />{item?.address} </h2>}
                                                </Link>
                                            </div>)}

                                    </Slider>

                                </div>
                            </div>
                        </section>



                    </div>

                    <HospitalEnquiry hospitalId={hospitalId} />



                </div>
            </section>

            <Faq faqlist={hospitalFaq}/>


        </>
    )
}
export default HospitalDetails;