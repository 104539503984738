import React, { useState, useEffect } from 'react'


import facebook from '../../assets/images/facebook-icon.svg'
import instagram from '../../assets/images/instagram-icon.svg'
import twitter from '../../assets/images/twitter-icon.svg'
import linkedIn from '../../assets/images/linkedin-icon.svg'
import tewntyFour from '../../assets/images/24-7-icon.svg'
import biz from '../../assets/images/biz-icon.svg'
// import loginIcon from '../../assets/images/login-icon.svg'
// import en from '../../assets/images/ln-icon.svg'
import axios from 'axios'
import apiUrl from '../../services/apiUrl'
import { useSelector } from 'react-redux'

import { Link } from 'react-router-dom'

function Header() {
    useEffect(() => {
        getSocialLInk()

    }, [])

    const [fb, setfb] = useState('')
    const [twitterLink, settwitter] = useState('')
    const [instagramLink, setinstagramLink] = useState('')
    const [linkedLink, setlinkedinLink] = useState('')
    const settings = useSelector(state => state.settings.value)


    const getSocialLInk = async () => {
        try {
            var response = await axios.get(apiUrl.getFooterData)
            if (response?.data?.status && response?.data?.data?.settings) {
                setfb(response.data.data.settings.facebook)
                settwitter(response.data.data.settings.twitter)
                setinstagramLink(response.data.data.settings.instagram)
                setlinkedinLink(response.data.data.settings.linkedin)
            }
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>
            <section>
                <div className="col-12 top-header">
                    <div className="logo">
                        <a href='/'><img src={`${apiUrl.host}/storage/${settings?.light_logo}`} alt="logo" /></a>
                    </div>
                    <ul className="ul-list-item p-0 d-none">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">About Us</a></li>
                        <li><a href="#">Treatment</a></li>
                        <li><a href="#">Hospitals</a></li>
                        <li><a href="#">Doctors</a></li>
                        <li><a href="#">Patients Voice</a></li>
                        <li><a href="#">Blog</a></li>
                        <li><a href="#">Contact Us</a></li>
                    </ul>
                    <div className="header-right">
                        <div className="social-icons">
                            <a href={fb} target="_blank"><img src={facebook} alt="facebook" /></a>
                            <a href={instagramLink} target="_blank"><img src={instagram} alt="instagram" /></a>
                            <a href={twitterLink} target="_blank"><img src={twitter} alt="twitter" /></a>
                            <a href={linkedLink} target="_blank"><img src={linkedIn} alt="linkedin" /></a>
                        </div>
                        <ul>
                            {/* <li><Link to="/contact" className='text-white'><img src={tewntyFour} />24/7 Support </Link></li>
                            <li><Link to="/business" className='text-white'><img src={biz} alt="" />MMA Biz for Corporate</Link></li> */}
                            {/* <li><img src={loginIcon} alt="" />Login</li> */}
                            {/* <li>
                                <div className="btn-group show-on-hover">
                                    <button type="button" className="btn btn-default">
                                        English <img src={en} alt="" />
                                    </button>

                                </div>
                            </li> */}
                            <li className="p-0"><Link to="/contact" className="enquiry">Enquiry </Link></li>


                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Header

