import React, { useState, useEffect } from 'react'


//icons

import placeHolder from '../../assets/images/placeholder.png'

import brainIcon from '../../assets/images/brain-icon.svg'
import surgonIcon from '../../assets/images/surgeon-icon.svg'
import orthopidiac from '../../assets/images/orthopedics-icon.svg'
import fertilityIcon from '../../assets/images/fertility-icon.svg'
import heartIcon from '../../assets/images/heart-icon.svg'


import '../../assets/css/treatmentsection.scoped.css'
import axios from 'axios'
import apiUrl from '../../services/apiUrl'

function Treatmentsection({ selectedState }) {
    const [treatements, settreatements] = useState([])

    useEffect(() => {
        getTreatment()
    }, [selectedState])



    const getTreatment = async () => {
        try {
            var response = await axios.post(apiUrl.getStateBasedTreatment, { state_id: selectedState })
            
            if (response?.data?.data?.treatments) {
                settreatements(response.data.data.treatments)
            }
        } catch (error) {
            console.error(error)
        }
    }



    return (

        <div>
            <section className="treatment">
                <div className="col-12 main-pd text-center">
                    <div className="row">
                        {treatements.map(item =>
                            <div key={item.id} className="col-4 list-item">
                                <div className="treatment-list position-relative">
                                    <img src={item?.logo ? `${apiUrl.host}/storage/${item.logo}`: placeHolder } alt="" width="70" height="70" />
                                    <h2>{item?.category_name}</h2>
                                    {item?.treatments && <div className="content">
                                        {item?.treatments.map((newItem,index) => <p key={index}> {newItem} <br /> </p>)}
                                    </div>}
                                </div>
                            </div>)}
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Treatmentsection

