import React from 'react'
import { useEffect } from 'react'

import hospitalicon from '../assets/images/hospital-icon.svg'
import packageicon from '../assets/images/package.png'

import Header from '../components/common/header'
import Hero from '../components/common/hero'


import '../assets/css/package/package-list.scoped.css'



function PackageslistPage() {


    return (
        <>
            <Hero />
            <section>
                <div className="row col-12 packages main-pd text-center">
                    <div className="tl-contr float-start w-100">
                        <h1 className="text-start float-start">Top Packages</h1>
                        <div className="float-end">
                            <label className="t-600">Sort By :</label>
                            <ul className="country-contr">
                                <li>
                                    <select name="" id="">
                                        <option value="">Country</option>
                                        <option value="">2</option>
                                    </select>
                                </li>
                                <li>
                                    <select name="" id="">
                                        <option value="">Speciality</option>
                                        <option value="">2</option>
                                    </select>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className="col-4">
                        <div className="item-list">
                            <div className="hospital">
                                <img src={hospitalicon} alt="" />
                                <div className="c-one">Venkateshwar Hospital, India</div>
                            </div>
                            <div className="position-relative">
                                <img src={packageicon} alt="" />
                            </div>
                            <h2>Dental Package</h2>
                            <div className="benefit">
                                <h2>Benefits</h2>
                                <ul>
                                    <li>2 Days Hospital</li>
                                    <li>5 Days Hospital</li>
                                    <li>Bollywood smile etc.</li>
                                </ul>
                                <div className="view">View Details</div>
                            </div>
                            <div className="botm-content">
                                <p><span>Our Price</span>$ 900</p>
                                <p className="text-end color">$ 900<span>(10%) Discount</span></p>
                                <button type="button" className="book-now">Book @ 10% | Pay USD 90</button>
                            </div>
                        </div>
                    </div>


                    <div className="col-4">
                        <div className="item-list">
                            <div className="hospital">
                                <img src={hospitalicon} alt="" />
                                <div className="c-one">Venkateshwar Hospital, India</div>
                            </div>
                            <div className="position-relative">
                                <img src={packageicon} alt="" />
                            </div>
                            <h2>Dental Package</h2>
                            <div className="benefit">
                                <h2>Benefits</h2>
                                <ul>
                                    <li>2 Days Hospital</li>
                                    <li>5 Days Hospital</li>
                                    <li>Bollywood smile etc.</li>
                                </ul>
                                <div className="view">View Details</div>
                            </div>
                            <div className="botm-content">
                                <p><span>Our Price</span>$ 900</p>
                                <p className="text-end color">$ 900<span>(10%) Discount</span></p>
                                <button type="button" className="book-now">Book @ 10% | Pay USD 90</button>
                            </div>
                        </div>
                    </div>


                    <div className="col-4">
                        <div className="item-list">
                            <div className="hospital">
                                <img src={hospitalicon} alt="" />
                                <div className="c-one">Venkateshwar Hospital, India</div>
                            </div>
                            <div className="position-relative">
                                <img src={packageicon} alt="" />
                            </div>
                            <h2>Dental Package</h2>
                            <div className="benefit">
                                <h2>Benefits</h2>
                                <ul>
                                    <li>2 Days Hospital</li>
                                    <li>5 Days Hospital</li>
                                    <li>Bollywood smile etc.</li>
                                </ul>
                                <div className="view">View Details</div>
                            </div>
                            <div className="botm-content">
                                <p><span>Our Price</span>$ 900</p>
                                <p className="text-end color">$ 900<span>(10%) Discount</span></p>
                                <button type="button" className="book-now">Book @ 10% | Pay USD 90</button>
                            </div>
                        </div>
                    </div>


                    <div className="col-4">
                        <div className="item-list">
                            <div className="hospital">
                                <img src={hospitalicon} alt="" />
                                <div className="c-one">Venkateshwar Hospital, India</div>
                            </div>
                            <div className="position-relative">
                                <img src={packageicon} alt="" />
                            </div>
                            <h2>Dental Package</h2>
                            <div className="benefit">
                                <h2>Benefits</h2>
                                <ul>
                                    <li>2 Days Hospital</li>
                                    <li>5 Days Hospital</li>
                                    <li>Bollywood smile etc.</li>
                                </ul>
                                <div className="view">View Details</div>
                            </div>
                            <div className="botm-content">
                                <p><span>Our Price</span>$ 900</p>
                                <p className="text-end color">$ 900<span>(10%) Discount</span></p>
                                <button type="button" className="book-now">Book @ 10% | Pay USD 90</button>
                            </div>
                        </div>
                    </div>


                    <div className="col-12">
                        <div className="text-start best-package">
                            <h2>Still Confuse to find a best package?</h2>
                            <p>8 out of 10 person requires experienced opinion to find a doctor for their treatment and we have experiences more than a decade.</p>
                            <div className="contact t-600 text-center">
                                Contact now
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </>
    )
}

export default PackageslistPage
