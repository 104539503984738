import React, { useState, useEffect } from 'react'

import placeHolder from '../../assets/images/placeholder.png'
import doctorPlaceHolder from '../../assets/images/doctorplaceholder.png'

import '../../assets/css/patienttestimonials.scoped.css'

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import apiUrl from '../../services/apiUrl';
import parse from 'html-react-parser';



function Patienttestimonials() {

    const [patientTestimonials, setpatientTestimonials] = useState([])

    useEffect(() => {
        getPatientTestimonials()
    }, [])


    const getPatientTestimonials = async () => {
        try {
            var response = await axios.post(apiUrl.getCms, { category_id: 8 })
            if (response?.data?.status && response?.data?.data?.cms) {
                setpatientTestimonials(response.data.data.cms)
            }
        } catch (error) {
            console.log(error)

        }
    }


    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            Previous
        </button>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            Next
        </button>
    );

    const settings = {
        dots: false,
        infinite: false,
        autoplay:true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrow: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                dots: false
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };


    return (
        <section>
            <div className="col-12 testimonial-contr main-pd text-center">
                <div className="tl-contr to-top">
                    <h1>Patient Testimonials</h1>
                </div>
                <div className="row testimonial">

                    <Slider {...settings}>

                        {patientTestimonials.map((item,index) => <div className="col-4" key={index}>
                            <div className="item">
                            <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : doctorPlaceHolder } alt="" />
                                <h3>{item.title}</h3>
                                <p>{item.sub_title}</p>
                                <div className="testi-content">
                                    {parse(`${item.content}`)}
                                </div>

                            </div>
                        </div>)}

                    </Slider>



                </div>
            </div>
        </section>
    )
}

export default Patienttestimonials
