import React, { useEffect, useState } from 'react'
import Hero from '../components/common/hero'

import '../assets/css/hospital/hospitalappoinment.scoped.css'

import lsbg from '../assets/images/ls-bg.png'
import showcase from '../assets/images/showcase.svg'
import reputation from '../assets/images/reputation.svg'
import growth from '../assets/images/growth.svg'
import Branding from '../assets/images/Branding.svg'
import tsLogo from '../assets/images/ts-logo.png'
import buildImg from '../assets/images/build-img.png'
import BusinessListEnquiry from '../components/enquiry/businessListEnquiry';
import { useSelector } from 'react-redux'

import parse from 'html-react-parser'


import axios from 'axios'
import apiUrl from '../services/apiUrl'

function ListHospital() {

    const [showcase, setshowcase] = useState({})
    const [reputation, setreputation] = useState({})
    const [intrnationalBusiness, setintrnationalBusiness] = useState({})
    const [promotion, setpromotion] = useState({})
    const [brandList, setbrandList] = useState({})
    const [profile, setprofile] = useState({})
    const settings = useSelector(state => state.settings.value)




    useEffect(() => {
        getShowCase()
        getreputation()
        getInternationalBusiness()
        getpromotion()
        getBrandLIst()
        getAttractiveProfile()

    }, [])




    const getShowCase = async () => {
        try {
            var response = await axios.post(apiUrl.getListHospitalCms, { slug: 'show case' })
            if (response?.data?.data) {
                setshowcase(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getreputation = async () => {
        try {
            var response = await axios.post(apiUrl.getListHospitalCms, { slug: "online reputation" })
            if (response?.data?.data) {
                setreputation(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getInternationalBusiness = async () => {
        try {
            var response = await axios.post(apiUrl.getListHospitalCms, { slug: "international business" })
            if (response?.data?.data) {
                setintrnationalBusiness(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getpromotion = async () => {
        try {
            var response = await axios.post(apiUrl.getListHospitalCms, { slug: "branding and promotion" })
            if (response?.data?.data) {
                setpromotion(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getBrandLIst = async () => {
        try {
            var response = await axios.post(apiUrl.getListHospitalCms, { slug: "trusted brands" })
            console.log(response.data)
            if (response?.data?.data) {
                setbrandList(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getAttractiveProfile = async () => {
        try {
            var response = await axios.post(apiUrl.getListHospitalCms, { slug: "attractive profile" })
            console.log(response.data)
            if (response?.data?.data) {
                setprofile(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <Hero />
            <section>
                <div className="main-pd position-relative fmbg-main">
                    <img src={lsbg} alt="" className="fm-bg" />
                    <div className="row forms-cn">
                        <div className="col-6 bn-content">
                            <h1>Sagittis id dapibus massa pellentesque consectetur in sed.</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                        <BusinessListEnquiry />
                    </div>
                </div>
            </section>


            <section>
                <div className="main-pd row show-main">
                    <div className="col-3">
                        <div className="show-list">
                            <div className="img-icon">
                                <img src={`${apiUrl.host}/storage/${showcase?.logo}`} alt="" />
                            </div>
                            <h2>{showcase?.title}</h2>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="show-list">
                            <div className="img-icon">
                                <img src={`${apiUrl.host}/storage/${reputation?.logo}`} alt="" />
                            </div>
                            <h2>{reputation?.title}</h2>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="show-list">
                            <div className="img-icon">
                                <img src={`${apiUrl.host}/storage/${intrnationalBusiness?.logo}`} alt="" />
                            </div>
                            <h2>{intrnationalBusiness?.title}</h2>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="show-list">
                            <div className="img-icon">
                                <img src={`${apiUrl.host}/storage/${promotion?.logo}`} alt="" />
                            </div>
                            <h2>{promotion?.title}</h2>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="row main-pd trusted-brands">
                    <div className="tl-contr float-start w-100">
                        <h1 className="text-start float-start">Some trusted brands has put faith in us</h1>
                    </div>

                    {parse(`${brandList?.content || ''}`)}
                    {/* <div className="ts-logo" key={item.id}><img src={`/storage${item?.logo}`} /></div> */}

                </div>
            </section>

            <section>
                <div className="row main-pd attractive-profile">
                    <div className="col-6">
                        <img src={profile?.image ? `${apiUrl.host}/storage/${profile?.image}` :buildImg} alt="" />
                    </div>
                    <div className="col-6">
                        <div className="tl-contr float-start w-100">
                            <h1 className="text-start float-start">We build the <span>most attractive</span> profile</h1>
                        </div>
                        <p>We demonstrate the most suitable information about your facilty includung:</p>

                        {parse(`${profile?.content || ''}`)}

                        {/* <ul>
                            <li>Brand</li>
                            <li>International Accreditations {'&'} Certifications</li>
                            <li>Procedures offered at your facility</li>
                            <li>Top doctors and their specialities and experience Media {'&'} Gallery</li>
                            <li>Options to send Enquires</li>
                            <li>Reviews and ratings</li>
                        </ul> */}

                    </div>
                </div>
            </section>


            <section>
                <div className="row main-pd trusted-brands">
                    <div className="tl-contr float-start w-100">
                        <h1 className="text-start float-start">Some Numbers / Statistics</h1>
                    </div>
                    <div className="tsc-logo">
                        <h1 className="t-700">{settings?.lead_generated || ''}+</h1>
                        <p>Leads generated per month</p>
                    </div>
                    <div className="tsc-logo">
                        <h1 className="t-700">{settings?.countries_reached_digitally || ''}+</h1>
                        <p>Countries reached digitally</p>
                    </div>
                    <div className="tsc-logo">
                        <h1 className="t-700">{settings?.healthcare_provider || ''}+</h1>
                        <p>Healthcare providers on panel</p>
                    </div>
                    <div className="tsc-logo">
                        <h1 className="t-700">{settings?.top_medical_destination || ''}+</h1>
                        <p>Top medical Destnations</p>
                    </div>
                    <div className="tsc-logo">
                        <h1 className="t-700">{settings?.unique_website_users || ''}+</h1>
                        <p>Unique website users per month</p>
                    </div>
                </div>
            </section>



            <section>
                <div className="row main-pd">
                    <div className="offer-contr">
                        <h1 className="t-600">Offers for your Hospital Promotion</h1>
                        <p>For special promotional packages, please write us at helpdesk@marlinmedicalassistance.com</p>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ListHospital
