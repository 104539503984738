
import React, { useState, useEffect } from 'react'

import Aboutus from '../components/common/aboutus'
import Hospitalhero from '../components/common/hospitalhero'
import Treatmentsection from '../components/common/treatmentsection'
import Topdoctors from '../components/common/topdoctors'
import Packages from '../components/common/packages'
import Topdoctors2 from '../components/common/topdoctors2'
import Awardsection from '../components/common/awardsection'
import Healthsection from '../components/common/healthsection'
import News from '../components/common/news'
import Patienttestimonials from '../components/common/patienttestimonials'
import Mapsection from '../components/common/map'
import Hero from '../components/common/hero'
import Header from '../components/common/header'
import Faq from '../components/common/faq'


import axios from 'axios'
import apiUrl from '../services/apiUrl'


function Homepage() {

    const [faqs, setfaqs] = useState([])

    useEffect(() => {
        getFaq()
    }, [])
    const getFaq = async () => {
        try {
            var response = await axios.get(apiUrl.getFaq)
            if (response?.data?.status && response?.data?.data?.faqs) {
                setfaqs(response.data.data.faqs)
            }
        } catch (error) {
            console.log(error)

        }

    }

    return (
        <div>
            <Hero showButton={true} />
            <Aboutus />
            <Hospitalhero />
            <Topdoctors />
            <Packages />
            <Topdoctors2 />
            <Awardsection />
            <Healthsection />
            <News />
            <Patienttestimonials />
            <Mapsection />
            <Faq faqlist={faqs} />


        </div>
    )
}

export default Homepage
