import React from 'react'


import Medicinepackages from '../components/common/medicinepackages'
import Hero from '../components/common/hero'
import Header from '../components/common/header'




function Medicine() {
    return (
        <div>
            <Hero title="MEDCINE"/>
            <Medicinepackages/>
            
        </div>
    )
}

export default Medicine
