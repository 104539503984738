import { BrowserRouter, Routes, Route, Link, useLocation } from 'react-router-dom'
import { useLayoutEffect } from 'react'


const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        window.scrollTo({top:0,left:0, behavior:"smooth"});
    }, [location.pathname]);
    return children
}



export default Wrapper