import React from 'react'

function Faq(props) {
    return (
        <>

            <section className="faq-contr">
                <div className="main-pd">
                    {props.faqlist.length > 0 && <div className="tl-contr w-100">
                        <h1 className="text-start float-start">FAQ</h1>
                    </div>}
                    <div className="col-12 accordion" id="accordionExample">

                        {props.faqlist.map(item =>
                            <div className="accordion-item" key={item.id}>
                                <h2 className="accordion-header t-700" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${item.id}`} aria-expanded="true" aria-controls="collapseOne">
                                        {item.question}
                                    </button>
                                </h2>
                                <div id={`collapse${item.id}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        {item.solution}
                                    </div>
                                </div>
                            </div>)}


                    </div>
                </div>
            </section>

        </>
    )
}

export default Faq
