import React from 'react'


import ds from '../assets/images/ds.png'
import doctorIcon from '../assets/images/b-doctor.png'
import hospitalPlaceIcon from '../assets/images/hospital-place-icon.svg'
import hospitalIcon from '../assets/images/hospital-icon.svg'
import packageIcon from '../assets/images/package.png'
import rBlog from '../assets/images/r-blog.png'
import Hero from '../components/common/hero'
import Header from '../components/common/header'


import '../assets/css/treatment/treatmentDetails.scoped.css'

function TreatmentDetailsPage() {
    return (
        <>
            <Header />
            <Hero />

            <section className="treatment-section">
                <div className="row main-pd">
                    <div className="col-8">
                        <section>
                            <div className="Excellence">
                                <img src={ds} alt="" />
                                <h2>Ortho Centre of Excellence</h2>
                                <ul>
                                    <li>TKR</li>
                                    <li>THR</li>
                                    <li>Arthroscopy</li>
                                    <li>Redo</li>
                                </ul>
                            </div>
                        </section>
                        <section>
                            <div className="col-12 best-doctor-contr text-center">
                                <div className="tl-contr float-start w-100">
                                    <h1 className="text-start float-start">Best Doctors</h1>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <div className="item">
                                            <img src={doctorIcon} alt="" />
                                            <h2>Dr.Hob Seark</h2>
                                            <h2 className="inline"><img src={hospitalPlaceIcon} alt="" /> Zentra Hospital, Delhi</h2>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="item">
                                            <img src={doctorIcon} alt="" />
                                            <h2>Dr.Hob Seark</h2>
                                            <h2 className="inline"><img src={hospitalPlaceIcon} alt="" /> Zentra Hospital, Delhi</h2>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="item">
                                            <img src={doctorIcon} alt="" />
                                            <h2>Dr.Hob Seark</h2>
                                            <h2 className="inline"><img src={hospitalPlaceIcon} alt="" /> Zentra Hospital, Delhi</h2>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>

                        <div className="tl-contr float-start w-100">
                            <h1 className="row text-start float-start">Most Popular Package for this Disease</h1>
                        </div>
                        <div className="row packages">
                            <div className="col-6">
                                <div className="item-list">
                                    <div className="hospital">
                                        <img src={hospitalIcon} alt="" />
                                        <div className="c-one">Venkateshwar Hospital, India</div>
                                    </div>
                                    <div className="position-relative">
                                        <img src={packageIcon} alt="" />
                                    </div>
                                    <h2>Dental Package</h2>
                                    <div className="benefit">
                                        <h2>Benefits</h2>
                                        <ul>
                                            <li>2 Days Hospital</li>
                                            <li>5 Days Hospital</li>
                                            <li>Bollywood smile etc.</li>
                                        </ul>
                                        <div className="view">View Details</div>
                                    </div>
                                    <div className="botm-content">
                                        <p><span>Our Price</span>$ 900</p>
                                        <p className="text-end color">$ 900<span>(10%) Discount</span></p>
                                        <button type="button" className="book-now">Book @ 10% | Pay USD 90</button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="item-list">
                                    <div className="hospital">
                                        <img src={hospitalIcon} alt="" />
                                        <div className="c-one">Venkateshwar Hospital, India</div>
                                    </div>
                                    <div className="position-relative">
                                        <img src={packageIcon} alt="" />
                                    </div>
                                    <h2>Dental Package</h2>
                                    <div className="benefit">
                                        <h2>Benefits</h2>
                                        <ul>
                                            <li>2 Days Hospital</li>
                                            <li>5 Days Hospital</li>
                                            <li>Bollywood smile etc.</li>
                                        </ul>
                                        <div className="view">View Details</div>
                                    </div>
                                    <div className="botm-content">
                                        <p><span>Our Price</span>$ 900</p>
                                        <p className="text-end color">$ 900<span>(10%) Discount</span></p>
                                        <button type="button" className="book-now">Book @ 10% | Pay USD 90</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="col-4 right-sec">
                        <div className="form-contr">
                            <h2>May I Know About Your Medical Needs?</h2>
                            <input type="text" placeholder="Name" className="form" />
                            <input type="email" placeholder="Email" className="form" />
                            <select name="" id="" aria-placeholder="Choose Country" className="form">
                                <option value="">1</option>
                            </select>
                            <textarea name="" id="" cols="30" rows="10" placeholder="Your Query"></textarea>
                            <input type="file" name="file-1[]" id="file-1" className="inputfile inputfile-1"
                                data-multiple-caption="{count} files selected" multiple="" />
                            <label htmlFor="file-1" className="form mnt">
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12.1875 9.625V12.25H1.875V9.625H0V13.125C0 13.6089 0.42 14 0.9375 14H13.125C13.6434 14 14.0625 13.6089 14.0625 13.125V9.625H12.1875Z"
                                        fill="#213B4D" />
                                    <path
                                        d="M10.6651 3.64963L7.38381 0.149625C7.29475 0.055125 7.16631 0 7.03131 0C6.89631 0 6.76787 0.055125 6.67881 0.149625L3.39756 3.64963C3.27662 3.77913 3.24662 3.962 3.32349 4.11862C3.39943 4.27437 3.56631 4.375 3.75006 4.375H5.62506V10.0625C5.62506 10.304 5.83506 10.5 6.09381 10.5H7.96881C8.22756 10.5 8.43756 10.304 8.43756 10.0625V4.375H10.3126C10.4963 4.375 10.6632 4.27525 10.7391 4.11862C10.8151 3.962 10.7869 3.77825 10.6651 3.64963Z"
                                        fill="#213B4D" />
                                </svg><span>Choose a file…</span>
                            </label>
                            <input type="submit" placeholder="Ask from our experts" className="form" />
                        </div>
                        <div className="recent-blog">
                            <h2>Recent Article / Blog</h2>
                            <div className="blog-list">
                                <img src={rBlog} alt="" />
                                <h2>Lorem Ipsum Simplmmy</h2>
                                <div className="content">Neque porro quisquam est qui doloremipsum quia dolor</div>
                            </div>
                            <div className="blog-list">
                                <img src={rBlog} alt="" />
                                <h2>Lorem Ipsum Simplmmy</h2>
                                <div className="content">Neque porro quisquam est qui doloremipsum quia dolor</div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>

    )
}

export default TreatmentDetailsPage
