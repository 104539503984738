import React, { useEffect, useState } from 'react'
import '../../assets/css/hospital/hospitalappoinment.scoped.css'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import apiUrl from '../../services/apiUrl';
import { message } from 'antd';
import 'antd/lib/message/style/index.css'

function BusinessListEnquiry() {

    const [submitiing, setsubmitiing] = useState(false)
    const [businessDocs, setbusinessDocs] = useState('')


    const schema = Yup.object({
        email: Yup.string().email('Invalid Email').required('Email Required'),
        hospitalName: Yup.string().required('Hospital Name Required'),
        location: Yup.string().required('Location Required'),
        phone: Yup.number().transform((currentValue, originalValue) => {
            return originalValue === '' ? null : currentValue;
        }).nullable().typeError('Must be a number').required('Contact Number Required'),
    })

    const { register, handleSubmit, reset,formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const submitEnquiry = async (data,e) => {
        
        setsubmitiing(true)
        try {
            var fdata = new FormData();
            fdata.append('hospital_name', data.hospitalName)
            fdata.append('email', data.email)
            fdata.append('contact', data.phone)
            fdata.append('location', data.location)
            fdata.append('file', businessDocs)

            var response = await axios.post(apiUrl.postHospitalListEnquiry, fdata, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            response.data.status ? message.success(response.data.message || 'Enguiry Recorded Successfully', 3) : message.error('Failed Record Engquiry', 3)
            setsubmitiing(false)
           
        } catch (error) {
            console.log(error)
            setsubmitiing(false)
            message.error('Failed Record Engquiry', 3)
        }finally{
            e.target.reset();
            reset()
            setbusinessDocs('')
        }

    }



    return (
        <>

            <div className="col-6 form-main">
                <div className="form-contr">
                    <form onSubmit={handleSubmit(submitEnquiry)}>
                        <label>Hospital Name</label>
                        <input type="text" placeholder="Enter Your Name" className="form"  {...register("hospitalName")} />
                        <div className='errmsg'>{errors.hospitalName?.message}</div>
                        <label>Email</label>
                        <input type="text" placeholder="Enter  Email" className="form"  {...register("email")} />
                        <div className='errmsg'>{errors.email?.message}</div>
                        <label>Contact Number</label>
                        <input type="text" placeholder="Enter Number" className="form"  {...register("phone")} />
                        <div className='errmsg'>{errors.phone?.message}</div>
                        <label>Location</label>
                        <input type="text" placeholder="Enter Location" className="form"  {...register("location")} />
                        <div className='errmsg'>{errors.location?.message}</div>
                        <div className="row">
                            <div className="col-6">
                                <input type="file" id="file-1" className="inputfile inputfile-1" onChange={(e) => { setbusinessDocs(e?.target?.files[0] || '') }} accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                <label htmlFor="file-1" className="form mnt">
                                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.1875 9.625V12.25H1.875V9.625H0V13.125C0 13.6089 0.42 14 0.9375 14H13.125C13.6434 14 14.0625 13.6089 14.0625 13.125V9.625H12.1875Z" fill="#213B4D" />
                                        <path d="M10.6651 3.64963L7.38381 0.149625C7.29475 0.055125 7.16631 0 7.03131 0C6.89631 0 6.76787 0.055125 6.67881 0.149625L3.39756 3.64963C3.27662 3.77913 3.24662 3.962 3.32349 4.11862C3.39943 4.27437 3.56631 4.375 3.75006 4.375H5.62506V10.0625C5.62506 10.304 5.83506 10.5 6.09381 10.5H7.96881C8.22756 10.5 8.43756 10.304 8.43756 10.0625V4.375H10.3126C10.4963 4.375 10.6632 4.27525 10.7391 4.11862C10.8151 3.962 10.7869 3.77825 10.6651 3.64963Z" fill="#213B4D" />
                                    </svg><span>{businessDocs.name || 'Attachment'}</span>
                                </label>
                            </div>
                            <div className="col-6">
                                <button type="submit" className="form mnt submit1">
                                    {submitiing ? <span className="spinner-border spinner-border-sm" role="status"> </span> : 'Send Details'}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}

export default BusinessListEnquiry
