import React from 'react'
import { useEffect, useState } from 'react'
import apiUrl from '../services/apiUrl'
import axios from 'axios';
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';

import { message } from 'antd';
import 'antd/lib/message/style/index.css'

//icons
import hospitalPlaceIcon from '../assets/images/hospital-place-icon.svg'

//css
import '../assets/css/home/contact.scoped.css'

import TitleBanner from '../components/common/title';
import Faq from '../components/common/faq';

function ContactPage() {

    const [contactData, setcontactData] = useState({
        "email": "",
        "customerSupport": "",
        "siteAppointment": ""
    });

    const [faqs, setfaqs] = useState([])
    const [branchState, setbranchState] = useState([])
    const [selectedBranch, setselectedBranch] = useState(0)
    const countryList = useSelector(state => state.countrylist.value)
    const [treatmentDocs, settreatmentDocs] = useState('')
    const [selectedFile, setselectedFile] = useState('')
    const [submiting, setsubmiting] = useState(false)
    const { register, handleSubmit,reset } = useForm()
    const [diseasesList, setdiseasesList] = useState([])



    useEffect(async () => {
        getFaq()
        getContactUS()
        getBranchList()
    }, [])

    React.useEffect(() => {
        getDiseases()
      }, [])
      
  
  
      const getDiseases = async()=>{
          try {
              var response = await axios.get(apiUrl.getDieseases)
              if(response?.data?.diseases){
                  setdiseasesList(response.data.diseases)
              }
              
          } catch (error) {
              console.log(error)
          }
      }
  

    const getContactUS = async () => {
        try {
            var result = await axios.get(apiUrl.getContactUS)
            if (result?.status && result?.data?.data) {
                setcontactData(result.data.data)
            } else {
                console.log('data not recived')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getBranchList = async () => {
        try {
            var result = await axios.get(apiUrl.getBranchList)
            if (result?.status && result?.data?.data?.branches) {
                setbranchState(result.data.data.branches)
            }

        } catch (error) {
            console.log(error)
        }
    }



    const submitEnquiry = async (data,e) => {
        setsubmiting(true)
        try {
            var fdata = new FormData();
            fdata.append('file', treatmentDocs)
            fdata.append('email', data.email)
            fdata.append('diseases_id', data.diseases)
            fdata.append('message', data.query)
            fdata.append('name', data.firstName)

            var response = await axios.post(apiUrl.setContactEnquiry, fdata, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })

            if (response.data.status) {
                message.success(response.data.message || 'Enguiry Recorded Successfully', 3)
                setsubmiting(false)

            } else {
                setsubmiting(false)
                message.error('Failed Record Engquiry', 3)
            }

        } catch (error) {
            console.log(error)
            setsubmiting(false)
            message.error('Failed Record Engquiry', 3)
        }finally{
            reset()
            e.target.reset();
            settreatmentDocs('')
        }

    }

    const getFaq = async () => {
        try {
            var response = await axios.get(apiUrl.getFaq)
            if (response?.data?.status && response?.data?.data?.faqs) {
                setfaqs(response.data.data.faqs)
            }
        } catch (error) {
            console.log(error)
        }

    }



    return (
        <>
            <TitleBanner />
            <section className="treatment-section">
                <div className="row m-0 contct-contr">
                    <div className="col-6 left-sec">
                        <h1 className="t-600">Contact Us</h1>
                        <div className="en-contr">
                            <h2>{contactData.email}</h2>
                            <h3>Email us at</h3>
                            <br />
                            <h2>{contactData.customerSupport}</h2>
                            <h3>Call for customer support</h3>
                            <br />
                            <h2>{contactData.siteAppointment}</h2>
                            <h3>Call for an appointment</h3>
                        </div>
                        <h1 className="t-600">Our Branches</h1>
                        <select onChange={(e) => { setselectedBranch(e.target.value) }}>
                            {branchState.map((item, index) => <option key={index} value={index}>{item?.name}</option>)}

                        </select>
                        <div className="map-contr">
                            <div className="map-content">
                                {branchState.length > 0 && <p><img src={hospitalPlaceIcon} alt="" /> {branchState[selectedBranch].name}, {branchState[selectedBranch].location} </p>}
                                <div className="map">
                                    <a href={branchState[selectedBranch]?.map_url} target="_blank" className='text-dark'>Get Map</a>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="col-6 right-sec">
                        <div className="form-contr">
                            <h2>May I Know About Your Medical Needs?</h2>
                            <form onSubmit={handleSubmit(submitEnquiry)}>
                                <input type="text" placeholder="Name" {...register("firstName")} className="form" />
                                <input type="email" placeholder="Email" className="form" {...register("email")} required />
                                <select aria-placeholder="diseases" className="form" {...register("diseases")}>
                                    <option defaultValue hidden value="">Choose Diseases</option>
                                    {diseasesList.map(item => <option key={item.id} value={item.id}>{item.title}</option>)}
                                </select>
                                <textarea id="msgarea" cols="30" rows="10" placeholder="Your Query" {...register("query")}></textarea>
                                <input type="file" onChange={(e) => { settreatmentDocs(e?.target?.files[0] || '') }} name="file-1[]" id="file-1" className="inputfile inputfile-1"
                                    accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                <label className="form mnt" htmlFor="file-1" >
                                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.1875 9.625V12.25H1.875V9.625H0V13.125C0 13.6089 0.42 14 0.9375 14H13.125C13.6434 14 14.0625 13.6089 14.0625 13.125V9.625H12.1875Z"
                                            fill="#213B4D" />
                                        <path
                                            d="M10.6651 3.64963L7.38381 0.149625C7.29475 0.055125 7.16631 0 7.03131 0C6.89631 0 6.76787 0.055125 6.67881 0.149625L3.39756 3.64963C3.27662 3.77913 3.24662 3.962 3.32349 4.11862C3.39943 4.27437 3.56631 4.375 3.75006 4.375H5.62506V10.0625C5.62506 10.304 5.83506 10.5 6.09381 10.5H7.96881C8.22756 10.5 8.43756 10.304 8.43756 10.0625V4.375H10.3126C10.4963 4.375 10.6632 4.27525 10.7391 4.11862C10.8151 3.962 10.7869 3.77825 10.6651 3.64963Z"
                                            fill="#213B4D" />
                                    </svg><span>{treatmentDocs.name || 'Choose a file…'}</span>
                                </label>

                                {submiting ?
                                    <button type="submit" placeholder="Ask from our experts" className="form">
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>  {' Submiting Data ...'}
                                    </button> :

                                    <button type="submit" placeholder="Ask from our experts" className="form">
                                        Ask from our experts
                                    </button>}

                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <Faq faqlist={faqs} />

        </>
    )
}

export default ContactPage
