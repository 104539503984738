import React from 'react'
import { useState, useEffect } from 'react'


//icons
import hospitalPlaceIcon from '../../assets/images/hospital-place-icon.svg'
import placeHolder from '../../assets/images/placeholder.png'
import doctorPlaceHolder from '../../assets/images/doctorplaceholder.png'


import { Link } from 'react-router-dom';


import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import '../../assets/css/topdoctors.scoped.css'
import axios from 'axios'
import apiUrl from '../../services/apiUrl'
import Select from 'react-select'



function Topdoctors() {
    const [countryList, setcountryList] = useState([])
    const [selectdCountry, setselectdCountry] = useState(1)
    const [doctorList, setdoctorList] = useState([])
    const [stateList, setstateList] = useState([])
    const [selectedState, setselectedState] = useState('')
    const [department, setdepartment] = useState([])
    const [selectedDept, setselectedDept] = useState('')
    const [stateListSmall, setstateListSmall] = useState([])
    const [deptListSmall, setdeptListSmall] = useState([])
    const [doctorTemp, setdoctorTemp] = useState([])


    const newStates = stateList.map(item => { return { value: item.id, label: item.name } })
    const newCountry = countryList.map(item => ({ value: item.id, label: item.name }))
    const newDept = department.map(item => ({ value: item.id, label: item.name }))



    useEffect(async () => {
        getTopDoctor()
        getStateList()
        getDepartmentByCountry()
    }, [selectdCountry])



    useEffect(async () => {
        getCountry()
    }, [])



    const getCountry = async () => {
        try {
            var response = await axios.get(apiUrl.getCountry)
            if (response?.status && response?.data?.data?.country) {
                setcountryList(response.data.data.country)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getTopDoctor = async () => {
        try {
            var response = await axios.post(apiUrl.getTopDoctor, { country_id: selectdCountry })
            if (response?.data?.status && response?.data?.data?.topDoctors) {
                setdoctorList(response.data.data.topDoctors)
                setdoctorTemp(response.data.data.topDoctors)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const getStateList = async () => {
        try {
            var response = await axios.post(apiUrl.getStateListByCountry, { country_id: selectdCountry })
            if (response?.data?.data?.countryBasedState) {
                setstateList(response.data.data.countryBasedState)
                var singlestatelist = response.data.data.countryBasedState.filter((item, idx) => idx < 2)
                setstateListSmall(singlestatelist)

            }
        } catch (error) {
            console.error(error)
        }
    }

    const topDoctorByState = async (sta = selectedState) => {

        try {
            var response = await axios.post(apiUrl.getTopDoctorByState, { state_id: sta })
            if (response?.data?.data?.topDoctors) {
                setdoctorList(response.data.data.topDoctors)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getDepartmentByCountry = async () => {
        try {
            var response = await axios.post(apiUrl.getDepartmentByCountry, { country_id: selectdCountry })
            if (response?.data?.data.countryBasedDepartments) {
                setdepartment(response.data.data.countryBasedDepartments)
                var singledeptlist = response.data.data.countryBasedDepartments;
                setdeptListSmall(singledeptlist)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const filterDoctor = async (dept) => {
        setselectedDept(dept);
        var doctorList = doctorTemp.filter(item => item.department_id === dept);
        setdoctorList(doctorList)
    }


    // const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    //     <button
    //         {...props}
    //         className={
    //             "slick-prev slick-arrow" +
    //             (currentSlide === 0 ? " slick-disabled" : "")
    //         }
    //         aria-hidden="true"
    //         aria-disabled={currentSlide === 0 ? true : false}
    //         type="button"
    //     >
    //         Previous
    //     </button>
    // );

    // const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    //     <button
    //         {...props}
    //         className={
    //             "slick-next slick-arrow" +
    //             (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    //         }
    //         aria-hidden="true"
    //         aria-disabled={currentSlide === slideCount - 1 ? true : false}
    //         type="button"
    //     >
    //         Next
    //     </button>
    // );



    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrow: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };

    return (
        <section>
            <div className="col-12 best-doctor-contr main-pd text-center">
                <div className="tl-contr float-start w-100">
                    <h1 className="text-start float-start">Best Doctors</h1>
                    <div className="float-end position-relative">
                        <ul className="country-contr">
                            <li onClick={() => { setselectdCountry(countryList[0]?.id) }} className={countryList[0]?.id === selectdCountry ? 'active' : undefined}>{countryList[0]?.name}</li>
                            <li onClick={() => { setselectdCountry(countryList[1]?.id) }} className={countryList[1]?.id === selectdCountry ? 'active' : undefined}>{countryList[1]?.name}</li>

                            {countryList.length > 2 &&
                                <li className="choose-item"><Select options={newCountry} onChange={(e) => { setselectdCountry(e.value) }}>

                                </Select>
                                </li>}
                        </ul>
                        <div className="radio-btn-contr">
                            <div className="row">

                                {deptListSmall[0]?.id && <div className="col">
                                    <label className="radio-btn">{deptListSmall[0]?.name}
                                        <input type="radio" readOnly checked={selectedDept === deptListSmall[0]?.id} onClick={() => { filterDoctor(deptListSmall[0]?.id) }} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>}

                                {deptListSmall[1]?.id && <div className="col">
                                    <label className="radio-btn">{deptListSmall[1]?.name}
                                        <input type="radio" readOnly checked={selectedDept === deptListSmall[1]?.id} onClick={() => { filterDoctor(deptListSmall[1]?.id) }} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>}


                                {deptListSmall.length > 3 &&
                                    <div className="col">
                                        <Select className={'w-100'} options={newDept} placeholder={'More'}  onChange={(e) => { filterDoctor(parseInt(e.value)) }}>
                                            
                                        </Select>
                                    </div>}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row ps-slide">

                    <Slider {...settings} >

                        {doctorList.map((item, index) =>
                            <div className="col-3 ds-item" key={index}>
                                <Link to={`doctor/${item.id}`}>
                                    <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : doctorPlaceHolder} style={{ objectFit: 'cover' }} alt="" className='img-fluid' />
                                </Link>
                                <h2>{item.name}</h2>
                                {item?.hospitalDetails?.cityDetails?.name && <h2 className="inline"><img src={hospitalPlaceIcon} alt="" /> {item?.hospitalDetails?.cityDetails?.name}</h2>}

                            </div>
                        )}

                        {doctorList.length === 0 && <span>No Data to Show !</span>}

                        {/* <div className="col-3">
                            <img src={doctorIcon} alt="" />
                            <h2>Dr.Hob Seark</h2>
                            <h2 className="inline"><img src={hospitalPlaceIcon} alt="" /> Zentra Hospital, Delhi</h2>
                        </div>

                        <div className="col-3">
                            <img src={doctorIcon} alt="" />
                            <h2>Dr.Hob Seark</h2>
                            <h2 className="inline"><img src={hospitalPlaceIcon} alt="" /> Zentra Hospital, Delhi</h2>
                        </div>

                        <div className="col-3">
                            <img src={doctorIcon} alt="" />
                            <h2>Dr.Hob Seark</h2>
                            <h2 className="inline"><img src={hospitalPlaceIcon} alt="" /> Zentra Hospital, Delhi</h2>
                        </div>

                        <div className="col-3">
                            <img src={doctorIcon} alt="" />
                            <h2>Dr.Hob Seark</h2>
                            <h2 className="inline"><img src={hospitalPlaceIcon} alt="" /> Zentra Hospital, Delhi</h2>
                        </div>

                        <div className="col-3">
                            <img src={doctorIcon} alt="" />
                            <h2>Dr.Hob Seark</h2>
                            <h2 className="inline"><img src={hospitalPlaceIcon} alt="" /> Zentra Hospital, Delhi</h2>
                        </div> */}

                    </Slider>

                </div>
                <div className="radio-btn-contr">
                    <h2>Find Best Doctors</h2>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        {stateListSmall.map(item =>
                            <div className="col" key={item.id}>
                                <label className={item.id === selectedState ? "radio-btn active" : "radio-btn"}>{item.name}
                                    <input type="radio" readOnly checked={true && item.id === selectedState} name="state" onClick={() => { setselectedState(item.id); topDoctorByState(item.id) }} />
                                    {selectedState === item.id && <span className="checkmark"></span>}
                                </label>
                            </div>
                        )}

                        <div className="col">
                            <Select placeholder={'More'} options={newStates} onChange={(e) => {setselectedState(e.value); topDoctorByState(e.value)}}>
                                
                            </Select>

                        </div>


                    </div>




                </div>
                <div className="tl-contr to-top">
                    <h1 className="text-center">Most Preferred Packages </h1>
                </div>
            </div>
        </section>
    )
}

export default Topdoctors

