import BlogPage from './pages/blog'
import Footer from "./components/common/footer";
import Getintouch from "./components/common/getintouch";
import ContactPage from "./pages/contact";
import PackageslistPage from "./pages/packageslist";
import TreatmentPage from "./pages/treatment"
import Medicine from "./pages/medicine";
import TreatmentlistPage from "./pages/treatmentlist"
import Homepage from "./pages/home";
import TreatmentDetailsPage from './pages/treatmentDetails'
import Hospitallist from './pages/hospitallist';
import Header from './components/common/header';
import BusinessPage from './pages/business';
import DoctorlistPage from './pages/doctorlist';
import Doctordetailspage from './pages/doctordetails';
import Blogdetailspage from './pages/blogdetails';
import TelemedicineDoctorPage from './pages/telemedicineDoctor';
import ForNotFound from './pages/404';
import HospitalDetails from './pages/hospitalDetails';
import ListHospital from './pages/listhospital';
import Wrapper from './components/common/scrollToTop'
import NewsDetailsPage from './pages/newsDetails';

import ScrollToTop from "react-scroll-to-top";




import './assets/fonts/TitilliumWeb-Bold.ttf'
import './assets/fonts/TitilliumWeb-Regular.ttf'
import './assets/fonts/TitilliumWeb-SemiBold.ttf'
import './assets/fonts/WorkSans-Medium.ttf'

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,

} from "react-router-dom";

function App() {
  return (

    <BrowserRouter>
      <Wrapper>
        <Header />

        <Routes>

          <Route path="/" element={<Homepage />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/packages" element={<PackageslistPage />} />
          <Route path="/treatment/:treatmentId/" element={<TreatmentPage />} />
          <Route path="/medicine" element={<Medicine />} />
          <Route path="/treatment/list" element={<TreatmentlistPage />} />
          <Route path="/treatment/details" element={<TreatmentDetailsPage />} />
          <Route path="/hospitals" element={<Hospitallist />} />
          <Route path="/hospital/:hospitalId/" element={<HospitalDetails />} />
          <Route path="/business" element={<BusinessPage />} />
          <Route path="/doctors" element={<DoctorlistPage />} />
          <Route path="/doctor/:doctorId" element={< Doctordetailspage />} />
          <Route path="/blog/:blogId" element={< Blogdetailspage />} />
          <Route path="/telemedicinedoctor" element={< TelemedicineDoctorPage />} />
          <Route path="/listhospital" element={< ListHospital />} />
          <Route path="/news/:newsId" element={< NewsDetailsPage />} />
          <Route path="/404" element={< ForNotFound />} />
          <Route path="*" element={< Navigate replace to="/404" />} />

        </Routes>

        <Getintouch />
        <Footer />
        <ScrollToTop smooth color={"#E6771B"} />

      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
