import React, { useState, useEffect } from 'react'

import placeHolder from '../../assets/images/placeholder.png'
import addOne from '../../assets/images/add1.png'
import chooseProduct from '../../assets/images/choose-product.svg'
import payment from '../../assets/images/payment.svg'
import weProcess from '../../assets/images/we-process.svg'
import productDeliverd from '../../assets/images/product-deliverd.svg'
import medicine from '../../assets/images/medicine.png'
import addTwo from '../../assets/images/add2.png'

import Slider from "react-slick";

import { Skeleton } from 'antd';
import 'antd/lib/skeleton/style/index.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/medcine/medicine.scoped.css'

import parse from 'html-react-parser'

import axios from 'axios'
import apiUrl from '../../services/apiUrl'



function Medicinepackages() {
    const [medicineList, setmedicineList] = useState([])
    const [topBrand, settopBrand] = useState([])
    const [loading, setloading] = useState(true)

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrow: true,
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 599,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
                breakpoint: 420,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
    };

    useEffect(() => {
        getMedcineList()
        getTopBrand()

    }, [])


    const getMedcineList = async () => {
        try {
            var response = await axios.get(apiUrl.getMedcineList)
           
            if (response.data.status && response.data.data.medicines) {
                setmedicineList(response.data.data.medicines)
                setloading(false)
            }
        } catch (error) {
            console.log(error)
            setloading(false)
        }
    }

    const getTopBrand = async () => {
        try {
            var response = await axios.get(apiUrl.getTopBrand)
            if (response.data.status && response.data.data.topBrands) {
                settopBrand(response.data.data.topBrands)
            }
        } catch (error) {
            console.log(error)
            setloading(false)
        }
    }

    return (
        <section>

            <div className="row col-12 packages main-pd text-center m-0">
                <div className="col-12">
                    <img src={addOne} alt="" />
                </div>
                <div className="col-12">
                    <div className="tl-contr float-start w-100 m-100">
                        <h2 className="ssd">HOW IT WORKS</h2>
                    </div>
                    <div className="row works-contr">
                        <div className="col-3 w-list">
                            <div className="work">
                                <img src={chooseProduct} alt="" />
                                <h1>Choose Product</h1>
                                <div className="content">
                                    Lobortis mauris est vitae dictum quis adipiscing a.
                                </div>
                            </div>
                        </div>
                        <div className="col-3 w-list">
                            <div className="work">
                                <img src={payment} alt="" />
                                <h1>Payment</h1>
                                <div className="content">
                                    Lobortis mauris est vitae dictum quis adipiscing a.
                                </div>
                            </div>
                        </div>
                        <div className="col-3 w-list">
                            <div className="work">
                                <img src={weProcess} alt="" />
                                <h1>We process</h1>
                                <div className="content">
                                    Lobortis mauris est vitae dictum quis adipiscing a.
                                </div>
                            </div>
                        </div>
                        <div className="col-3 w-list">
                            <div className="work">
                                <img src={productDeliverd} alt="" />
                                <h1>Product Delivered</h1>
                                <div className="content">
                                    Lobortis mauris est vitae dictum quis adipiscing a.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">

                    <div className="row medicine">

                        

                    <Skeleton loading={loading} active>

                        {medicineList.map(item => <div className="col-4 m-list" key={item.id}>
                            <div className="item-med">
                            <img src={item.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" />
                                <h2>{item.name}</h2>
                                <div className="content">
                                    {parse(`${item.description}`)}
                                </div>
                                <button type="button" className="book-now t-600">ORDER NOW</button>
                            </div>
                        </div>)}

                        {medicineList.length === 0 && loading && <h4>No Data to Show</h4>}

                    </Skeleton>


                    </div>
                </div>


                <div className="col-12">
                    <div className="tl-contr float-start w-100 m-100 mn-top2">
                        <h1 className="text-start float-start">Top Brands</h1>
                    </div>
                    <div className="row m-0 top-brand">

                        <Slider {...settings}>
                            {topBrand.map(item => 
                            <div className="col">
                                <div className="item-med">
                                    <img src={`${apiUrl.host}/storage/${item.image}`} alt="" />
                                </div>
                            </div>)}
                        </Slider>
                    </div>
                </div>


                <div className="col-12">
                    <div className="text-start best-package">
                        <img src={addTwo} alt="" />
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Medicinepackages
