
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useForm } from 'react-hook-form'

import { message } from 'antd';
import 'antd/lib/message/style/index.css'

import axios from 'axios'
import apiUrl from '../../services/apiUrl'
import { useDispatch } from 'react-redux'
import { setSelectedDept } from '../../features/search/selectedDept'
import { setsearchText } from '../../features/search/searchTextSlice'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

import HandleOutsideClick from "react-outside-click-event";
import placeHolder from '../../assets/images/placeholder.png'




import '../../assets/css/autosuggetion/treatmentSearch.scoped.css'

function TreatmentSearchBox(props) {
    // const selectedDept = useSelector(state => state.selectedDept.value)
    const [departmentList, setdepartmentList] = useState([])
    const searchText = useSelector(state => state.searchText.value)
    const [treatmentSuggetion, settreatmentSuggetion] = useState([])
    const [recentBlogs, setrecentBlogs] = useState([])
    const [submit, setsubmit] = useState(false)
    const [showSuggetion, setshowSuggetion] = useState(false)

    const dispatch = useDispatch()
    let location = useLocation()
    const { register, handleSubmit, reset, formState: { errors } } = useForm()

    useEffect(() => {
        getDepartmentList()
    }, [])

    useEffect(() => {
        setshowSuggetion(false)
    }, [location]);



    const getDepartmentList = async () => {
        try {
            var response = await axios.get(apiUrl.getDepartmentList)
            if (response?.data?.status && response?.data?.data?.departments) {
                setdepartmentList(response.data.data.departments)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getTreatmentSuggetion = async () => {
        setshowSuggetion(true)
        try {
            var response = await axios.post(apiUrl.getTreatmentSuggetion, { search_text: searchText })
            console.log(response.data)
            if (response?.data?.treatments) {
                settreatmentSuggetion(response.data.treatments)
            }
            if (response?.data?.recentBlogs) {
                setrecentBlogs(response?.data?.recentBlogs)
            }
        } catch (error) {
            console.log(error)
        }
    }



    const submitEnquiry = async (data, e) => {
        setsubmit(true)
        try {
            var response = await axios.post(apiUrl.setCommonEnquiry, {
                name: data.name,
                email: data.email,
                message: data.message
            })

            if (response.data?.status) {
                setsubmit(false)
                message.success(response.data?.message || 'Enquiry Recorded Successfully !', 3)
            } else {
                setsubmit(false)
                message.error('Failed Record Enquiry', 3)
            }
        } catch (error) {
            console.log(error)
            setsubmit(false)
            message.error('Failed Record Enquiry', 3)
        } finally {
            e.target.reset();
            reset()
        }
    }


    const suggtionController = (e) => {
        dispatch(setsearchText(e.target.value))
        getTreatmentSuggetion()
    }


    return (
        <>
            <div className="row g-0">
                <div className="col m-0">
                    <input type="search" className="form-control m-0 custom-input-box" placeholder='Search Here'
                        value={props.value}
                        onChange={(e) => suggtionController(e)} />
                </div>

                {<div className="col-md-4 m-0">
                    <select className="form-select form-select-lg mb-3 custom-select tl-sl" style={{ height: '100%' }}
                        onChange={(e) => { dispatch(setSelectedDept(e.target.value)) }}>
                        <option selected hidden>Choose Department</option>
                        {departmentList.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}

                    </select>
                </div>}
            </div>

            {/* {<div className="col-md-4 m-0">
                <select className="form-select form-select-lg mb-3 custom-select tl-sl" style={{ height: '100%' }} 
                onChange={(e)=>{dispatch(setSelectedDept(e.target.value))}}>
                    <option selected hidden>Choose Department</option>
                    {departmentList.map(item => <option value={item.id} key={item.id}>{item.name}</option>)} */}
            <HandleOutsideClick
                onOutsideClick={() => {
                   setshowSuggetion(false)
                }}
            >
                {treatmentSuggetion.length > 0 && showSuggetion &&
                    <section className="treatment-section">
                        <div className="row">

                            <div className="col-8" >
                                {treatmentSuggetion.map(item => <section key={item.id}>
                                    <div className="Excellence">
                                        <img src={`${apiUrl.host}/storage/${item?.image}`} alt="" />
                                        <Link to={`/treatment/${item.id}`} key={item.id}><h2 className='text-dark'>{item?.name}</h2></Link>
                                        {item?.options && <>
                                            <ul>
                                                {item.options.map((item, i) => <li key={i}>{item}</li>)}
                                            </ul>
                                        </>}
                                    </div>
                                </section>)}
                            </div>

                            <div className="col-4 right-sec">
                                <div className="form-contr">
                                    <form onSubmit={handleSubmit(submitEnquiry)}>
                                        <h2>Getting Touch</h2>
                                        <label>Your Name</label>
                                        <input type="text" placeholder="Enter Your Name" {...register("name")} className="form"></input>
                                        <label>Your Email</label>
                                        <input type="email" placeholder="Enter Your Email" {...register("email")} className="form" required></input>
                                        <label>Message</label>
                                        <textarea name="" id="" cols="30" rows="10" {...register("message")} placeholder="Message"></textarea>
                                        <button type="submit" className="form">
                                            {!submit ? 'Ask from our experts' : <span className="spinner-border spinner-border-sm" role="status"> </span>}
                                        </button>
                                    </form>
                                </div>
                                {recentBlogs && <div className="recent-blog">
                                    <h2>Recent Article  / Blog</h2>

                                    {recentBlogs.map(item => <div className="blog-list" key={item.id}>
                                    <img src={item?.image ? `${apiUrl.host}/storage/${item?.image}`: placeHolder} alt="" />
                                        <Link to={`/blog/${item.id}`}><h2 className='text-dark'>{item?.title}</h2></Link>
                                        <div className="content">{item?.mini_content}</div>
                                    </div>)}


                                </div>}
                            </div>
                        </div>
                    </section>}
            </HandleOutsideClick>



        </>
    )
}

export default TreatmentSearchBox
