import React from 'react'

function ForNotFound() {
    return (
        <div className='notfound'>

            <h1>404 page not found</h1>
            
        </div>
    )
}

export default ForNotFound;
