import React from 'react'


import hospitalIcon from '../../assets/images/hospital-icon.svg'
import packageIcon from '../../assets/images/package.png'


import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import '../../assets/css/packages.scoped.css'


function PackagesInDetails() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrow: true,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                dots: false
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]

    };


  

    return (
        <section>
            <div className="tl-contr float-start w-100">
                    <h1 className="row text-start float-start m-0">Popular Packages at this Hospital</h1>
            </div>
          
            <div className="row col-12 packages h-detail text-center">
                <div className="row m-0">

                    <Slider {...settings}>

                        <div className="col-4">
                            <div className="item">
                                <div className="hospital">
                                    <img src={hospitalIcon} alt="" />
                                    <div className="c-one">Venkateshwar Hospital, Indiaa</div>
                                </div>
                                <div className="position-relative imc">
                                    <img src={packageIcon} alt="" />
                                </div>
                                <h2>Dental Package</h2>
                                <div className="benefit">
                                    <h2>Benefits</h2>
                                    <ul>
                                        <li>2 Days Hospital</li>
                                        <li>5 Days Hospital</li>
                                        <li>Bollywood smile etc.</li>
                                    </ul>
                                    <div className="view">View Details</div>
                                </div>
                                <div className="botm-content">
                                    <p><span>Our Price</span>$ 900</p>
                                    <p className="text-end color">$ 900<span>(10%) Discount</span></p>
                                    <button type="button" className="book-now">Book @ 10% | Pay USD 90</button>
                                </div>
                            </div>
                        </div>


                        <div className="col-4">
                            <div className="item">
                                <div className="hospital">
                                    <img src={hospitalIcon} alt="" />
                                    <div className="c-one">Venkateshwar Hospital, Indiaa</div>
                                </div>
                                <div className="position-relative imc">
                                    <img src={packageIcon} alt="" />
                                </div>
                                <h2>Dental Package</h2>
                                <div className="benefit">
                                    <h2>Benefits</h2>
                                    <ul>
                                        <li>2 Days Hospital</li>
                                        <li>5 Days Hospital</li>
                                        <li>Bollywood smile etc.</li>
                                    </ul>
                                    <div className="view">View Details</div>
                                </div>
                                <div className="botm-content">
                                    <p><span>Our Price</span>$ 900</p>
                                    <p className="text-end color">$ 900<span>(10%) Discount</span></p>
                                    <button type="button" className="book-now">Book @ 10% | Pay USD 90</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="item">
                                <div className="hospital">
                                    <img src={hospitalIcon} alt="" />
                                    <div className="c-one">Venkateshwar Hospital, Indiaa</div>
                                </div>
                                <div className="position-relative imc">
                                    <img src={packageIcon} alt="" />
                                </div>
                                <h2>Dental Package</h2>
                                <div className="benefit">
                                    <h2>Benefits</h2>
                                    <ul>
                                        <li>2 Days Hospital</li>
                                        <li>5 Days Hospital</li>
                                        <li>Bollywood smile etc.</li>
                                    </ul>
                                    <div className="view">View Details</div>
                                </div>
                                <div className="botm-content">
                                    <p><span>Our Price</span>$ 900</p>
                                    <p className="text-end color">$ 900<span>(10%) Discount</span></p>
                                    <button type="button" className="book-now">Book @ 10% | Pay USD 90</button>
                                </div>
                            </div>

                        </div>
                            <div className="col-4">
                                <div className="item">
                                    <div className="hospital">
                                        <img src={hospitalIcon} alt="" />
                                        <div className="c-one">Venkateshwar Hospital, India</div>
                                    </div>
                                    <div className="position-relative imc">
                                        <img src={packageIcon} alt="" />
                                    </div>
                                    <h2>Dental Package</h2>
                                    <div className="benefit">
                                        <h2>Benefits</h2>
                                        <ul>
                                            <li>2 Days Hospital</li>
                                            <li>5 Days Hospital</li>
                                            <li>Bollywood smile etc.</li>
                                        </ul>
                                        <div className="view">View Details</div>
                                    </div>
                                    <div className="botm-content">
                                        <p><span>Our Price</span>$ 900</p>
                                        <p className="text-end color">$ 900<span>(10%) Discount</span></p>
                                        <button type="button" className="book-now">Book @ 10% | Pay USD 90</button>
                                    </div>
                                </div>
                            </div>


                            <div className="col-4">
                                <div className="item">
                                    <div className="hospital">
                                        <img src={hospitalIcon} alt="" />
                                        <div className="c-one">Venkateshwar Hospital, India</div>
                                    </div>
                                    <div className="position-relative imc">
                                        <img src={packageIcon} alt="" />
                                    </div>
                                    <h2>Dental Package</h2>
                                    <div className="benefit">
                                        <h2>Benefits</h2>
                                        <ul>
                                            <li>2 Days Hospital</li>
                                            <li>5 Days Hospital</li>
                                            <li>Bollywood smile etc.</li>
                                        </ul>
                                        <div className="view">View Details</div>
                                    </div>
                                    <div className="botm-content">
                                        <p><span>Our Price</span>$ 900</p>
                                        <p className="text-end color">$ 900<span>(10%) Discount</span></p>
                                        <button type="button" className="book-now">Book @ 10% | Pay USD 90</button>
                                    </div>
                                </div>
                            </div>
                    </Slider>
                </div>
            </div>
        </section>

    )
}

export default PackagesInDetails
