import React, { useState, useEffect } from 'react'
import health from '../../assets/images/health.png'
import loanIcon from '../../assets/images/loan-icon.svg'
import travelIcon from '../../assets/images/travel-icon.svg'
import cardIcon from '../../assets/images/card-icon.svg'
import parse from 'html-react-parser'
import placeHolder from '../../assets/images/placeholder.png'


import '../../assets/css/healthsection.scoped.css'
import axios from 'axios'
import apiUrl from '../../services/apiUrl'

function Healthsection() {
    const [healthSection, sethealthSection] = useState([])
    const [financial, setfinancial] = useState([])

    useEffect(() => {
        getHealthSection()
        getFinacial()
    }, [])



    const getHealthSection = async () => {
        try {
            var response = await axios.post(apiUrl.getCms, { category_id: 12 })
            if (response?.data?.data?.cms) {
                sethealthSection(response.data.data.cms)
            }
        } catch (error) {
            console.error(error)
        }
    }


    const getFinacial = async () => {
        try {
            var response = await axios.post(apiUrl.getCms,{category_id:14})
            if (response?.data?.data?.cms) {
                setfinancial(response.data.data.cms)
            }
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>

            <section>
                <div className="health-section">
                    <div className="row hs-fea main-pd m-0">

                        {financial.map(item =>
                            <div key={item.id} className="cn-center">
                                <div className="v-center">
                                    <img src={item?.logo ? `${apiUrl.host}/storage/${item.logo}`: placeHolder } alt="" />
                                    <h1>{item?.title || ''}</h1>
                                    <span id='contents'>{parse(`${item?.content || ''}`)} </span>
                                </div>
                            </div>)}

                    
                    </div>
                    <div className="row text-center m-0">
                        <div className="tl-contr to-top">
                            <h1>Our Healthcare Associates</h1>
                        </div>
                    </div>
                    <div className="row main-pd ns-contr">

                        {healthSection.map(item => <div key={item.id} className="health">
                            <img src={item?.logo ?`${apiUrl.host}/storage/${item.logo}` : placeHolder} alt="" />
                        </div>)}

                    </div>
                </div>
            </section>

        </div>
    )
}

export default Healthsection
