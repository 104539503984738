import { createSlice } from '@reduxjs/toolkit'

export const selectedDeptSlice = createSlice({
  name: 'selectedDept',
  initialState: {
    value: ''
  },
  reducers: {
    setSelectedDept: (state,action) => {
      state.value = action.payload
    }
   
  }
})

export const { setSelectedDept } = selectedDeptSlice.actions

export default selectedDeptSlice.reducer