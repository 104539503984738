import React, { useState, useEffect } from 'react'

import aboutBg from '../../assets/images/about-bg.png'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';


import '../../assets/css/aboutus.scoped.css'
import axios from 'axios';
import apiUrl from '../../services/apiUrl';
import parse from 'html-react-parser'

function Aboutus() {

    useEffect(async () => {
        getFeatured()
        getAboutUs()
    }, [])

    const [featured, setfeatured] = useState([])
    const [aboutUs, setaboutUs] = useState({})



    const getFeatured = async () => {
        try {
            var response = await axios.get(apiUrl.getFeatured)
            if (response?.data?.data?.featuredIn) {
            
                setfeatured(response.data.data.featuredIn)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getAboutUs = async () => {
        try {
            var response = await axios.post(apiUrl.getCms, { category_id: 13 })
            if (response?.data?.data?.cms) {
                setaboutUs(response?.data?.data?.cms[0])
            }
        } catch (error) {
            console.error(error)

        }
    }

    return (

        <section className="about-section">
            <div className="col-12 position-relative">
                <div className="abt-img">
                    <img src={aboutBg} alt="" />
                </div>
                <div className="about-content">
                    <h1>About Us</h1>
                    <SimpleBar forceVisible="y" style={{ maxHeight: 230 }}>
                        <div className="content" id="simple-bar">
                            {parse(`${aboutUs?.content || ''}`)}
                        </div>
                    </SimpleBar>
                </div>
            </div>
            <div className="col-12 teachable-contr d-flex">
                <div className="feature"><h2>Featured in:</h2></div>
                <div className="row caro-contr m-0">
                    <div className="item ab-caro">
                        <SimpleBar forceVisible="x" style={{ maxHeight: 200 }}>
                            <div className="content" id="simple-bar">
                                <div className="d-flex">
                                {parse(`${featured?.content || ''}`)}


                                </div>
                            </div>
                        </SimpleBar>
                    </div>
                    {/* <div className="col-2 item">
                        <a href="#">
                            <img src={teachable} alt="" />
                        </a>
                    </div>
                    <div className="col-2 item">
                        <a href="#">
                            <img src={teachable} alt="" />
                        </a>
                    </div>
                    <div className="col-2 item">
                        <a href="#">
                            <img src={teachable} alt="" />
                        </a>
                    </div>
                    <div className="col-2 item">
                        <a href="#">
                            <img src={teachable} alt="" />
                        </a>
                    </div> */}
                </div>
            </div>
        </section>

    )
}

export default Aboutus
