import React from 'react'
import axios from 'axios'
import apiUrl from '../services/apiUrl'
import { useEffect, useState, useMemo } from 'react'
import { Skeleton, Pagination } from 'antd';

import 'antd/lib/pagination/style/index.css'
import 'antd/lib/skeleton/style/index.css'
import { Link } from 'react-router-dom';


import Hero from '../components/common/hero'
import Faq from '../components/common/faq'
import placeHolder from '../assets/images/placeholder.png'

import '../assets/css/blog/blog.scoped.css'

let PageSize = 6

function BlogPage() {
    const [blogList, setblogList] = useState([])
    const [blogCategorys, setblogCategory] = useState([])
    const [selectedBlogCategory, setselectedBlogCategory] = useState(0)
    const [loading, setloading] = useState(true)
    const [faqs, setfaqs] = useState([])

    //pagination

    const [currentPage, setCurrentPage] = useState(1)

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return blogList.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, blogList, loading])




    useEffect(async () => {
        getBlogList()
        getBlogCategory()
        getBlogFaq()
    }, [])

    const getBlogList = async () => {
        setblogList([])
        try {
            var response = await axios.get(apiUrl.getBlogList)
            if (response?.status && response?.data?.data?.blogs) {
                setblogList(response.data.data.blogs)
                setloading(false)
            }
        } catch (error) {
            console.log(error)
            setblogList([])

        }
    }

    const getBlogListByCategory = async (id) => {
        setblogList([])
        setloading(true)
        await setselectedBlogCategory(id)

        getBlogFaq()
        try {
            var response = await axios.post(apiUrl.getBlogListByCategory, { category_id: id })
            if (response?.status && response?.data?.data?.categoryBasedBlog) {
                setblogList(response.data.data.categoryBasedBlog)
                setloading(false)
            }
        } catch (error) {
            console.log(error)
            setblogList([])
            setloading(false)

        }
    }

    const getBlogCategory = async () => {
        try {
            var response = await axios.post(apiUrl.getBlogCategory)
            if (response?.data?.status || response?.data?.data?.blogCategory) {
                setblogCategory(response?.data?.data?.blogCategory)
                setloading(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getBlogFaq = async () => {
        try {
            var response = await axios.get(apiUrl.getBlogFaq)
            if (response?.data?.status && response?.data?.data?.allBlogsFaq) {
                setfaqs(response.data.data.allBlogsFaq)
            }
        } catch (error) {
            console.log(error)

        }
    }

    return (
        <>
            <Hero title="BLOG" />
            <section className="blog-section">
                <div className="col-12 main-pd">
                    <nav>
                        <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                            {blogCategorys.map(item =>
                                <a key={item.id} onClick={() => { getBlogListByCategory(item.id) }} className={selectedBlogCategory === item.id ? "nav-item nav-link active" : "nav-item nav-link"} key={item.id} id="nav-home-tab" data-toggle="tab" role="tab" aria-controls="nav-home" style={{ cursor: 'pointer' }}>{item.title}</a>)}
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="speciality" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div className="row">
                                <Skeleton loading={loading} active>
                                    {currentTableData.map(item =>
                                        <div className="col-4 blog-listing" key={item.id}>
                                            <div className="blog-list">
                                                <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" />
                                                <h2>{item.title}</h2>
                                                <div className="content">
                                                    Vulputate elit elementum, ac euismod tincidunt feugiat.Tortor, viverra non, neque porttitor cursus vivamus sit ultricies sit.
                                                </div>
                                                <Link to={`/blog/${item.id}`}><svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.3399 7.18449C23.7304 7.57502 23.7304 8.20818 23.3399 8.59871L16.976 14.9627C16.5854 15.3532 15.9523 15.3532 15.5617 14.9627C15.1712 14.5721 15.1712 13.939 15.5617 13.5485L21.2186 7.8916L15.5617 2.23475C15.1712 1.84422 15.1712 1.21106 15.5617 0.820534C15.9523 0.430009 16.5854 0.430009 16.976 0.820534L23.3399 7.18449ZM22.6328 8.8916H0.75V6.8916H22.6328V8.8916Z" fill="#E6771B" />
                                                </svg>  <span>Read More</span></Link>
                                            </div>

                                        </div>)}

                                    {blogList.length === 0 && <h4 style={{ color: 'Black' }} className='mt-5'>No Data to Show</h4>}

                                </Skeleton>



                                {/* <div className="col-4">
                                    <div className="blog-list">
                                        <img src={blogIcon} alt="" />
                                        <h2>Black Fungus Infection: Symptoms, Treatment, and Causes</h2>
                                        <div className="content">
                                            Vulputate elit elementum, ac euismod tincidunt feugiat.Tortor, viverra non, neque porttitor cursus vivamus sit ultricies sit.
                                        </div>
                                        <a href="#"><svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.3399 7.18449C23.7304 7.57502 23.7304 8.20818 23.3399 8.59871L16.976 14.9627C16.5854 15.3532 15.9523 15.3532 15.5617 14.9627C15.1712 14.5721 15.1712 13.939 15.5617 13.5485L21.2186 7.8916L15.5617 2.23475C15.1712 1.84422 15.1712 1.21106 15.5617 0.820534C15.9523 0.430009 16.5854 0.430009 16.976 0.820534L23.3399 7.18449ZM22.6328 8.8916H0.75V6.8916H22.6328V8.8916Z" fill="#E6771B" />
                                        </svg> <span>Read More</span></a>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="blog-list">
                                        <img src={blogIcon} alt="" />
                                        <h2>Black Fungus Infection: Symptoms, Treatment, and Causes</h2>
                                        <div className="content">
                                            Vulputate elit elementum, ac euismod tincidunt feugiat.Tortor, viverra non, neque porttitor cursus vivamus sit ultricies sit.
                                        </div>
                                        <a href="#"><svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.3399 7.18449C23.7304 7.57502 23.7304 8.20818 23.3399 8.59871L16.976 14.9627C16.5854 15.3532 15.9523 15.3532 15.5617 14.9627C15.1712 14.5721 15.1712 13.939 15.5617 13.5485L21.2186 7.8916L15.5617 2.23475C15.1712 1.84422 15.1712 1.21106 15.5617 0.820534C15.9523 0.430009 16.5854 0.430009 16.976 0.820534L23.3399 7.18449ZM22.6328 8.8916H0.75V6.8916H22.6328V8.8916Z" fill="#E6771B" />
                                        </svg> <span>Read More</span></a>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="blog-list">
                                        <img src={blogIcon} alt="" />
                                        <h2>Black Fungus Infection: Symptoms, Treatment, and Causes</h2>
                                        <div className="content">
                                            Vulputate elit elementum, ac euismod tincidunt feugiat.Tortor, viverra non, neque porttitor cursus vivamus sit ultricies sit.
                                        </div>
                                        <a href="#"><svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.3399 7.18449C23.7304 7.57502 23.7304 8.20818 23.3399 8.59871L16.976 14.9627C16.5854 15.3532 15.9523 15.3532 15.5617 14.9627C15.1712 14.5721 15.1712 13.939 15.5617 13.5485L21.2186 7.8916L15.5617 2.23475C15.1712 1.84422 15.1712 1.21106 15.5617 0.820534C15.9523 0.430009 16.5854 0.430009 16.976 0.820534L23.3399 7.18449ZM22.6328 8.8916H0.75V6.8916H22.6328V8.8916Z" fill="#E6771B" />
                                        </svg> <span>Read More</span></a>
                                    </div>
                                </div> */}




                            </div>

                        </div>

                        {/* <div className="tab-pane fade" id="cost-guide" role="tabpanel" aria-labelledby="nav-profile-tab">
                            2
                        </div>
                        <div className="tab-pane fade" id="support" role="tabpanel" aria-labelledby="nav-contact-tab">
                            3
                        </div>
                        <div className="tab-pane fade" id="handbook" role="tabpanel" aria-labelledby="nav-about-tab">
                            4
                        </div>
                        <div className="tab-pane fade" id="patient-voice" role="tabpanel" aria-labelledby="nav-voice-tab">
                            5
                        </div> */}
                    </div>

                    <div className="text-end">
                        <Pagination style={{ borderColor: 'red' }} size={'large'} current={currentPage} total={blogList.length} onChange={page => setCurrentPage(page)} />

                    </div>

                </div>

            </section>



            <Faq faqlist={faqs} />

        </>
    )
}

export default BlogPage
