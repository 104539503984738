import { createSlice } from '@reduxjs/toolkit'

export const cityListSlice = createSlice({
  name: 'citylist',
  initialState: {
    value: []
  },
  reducers: {
    setCityList: (city,action) => {
      city.value = action.payload
    }
   
  }
})

export const { setCityList } = cityListSlice.actions

export default cityListSlice.reducer