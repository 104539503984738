import React, { useEffect, useState } from 'react'
import Hero from '../components/common/hero'
import { useSelector } from 'react-redux'
import { Skeleton, Pagination } from 'antd'
import 'antd/lib/skeleton/style/index.css'
// import 'antd/lib/pagination/style/index.css'
import 'antd/lib/empty/style/index.css'

import '../assets/css/doctor/doctorslist.scoped.css'

import doctorPlaceHolder from '../assets/images/doctorplaceholder.png'
import placeHolder from '../assets/images/placeholder.png'
import hsLogo from '../assets/images/hs-logo.png'
import lng from '../assets/images/lng-icon.svg'
import axios from 'axios'
import apiUrl from '../services/apiUrl'

import Faq from '../components/common/faq'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import { Empty } from 'antd';


function DoctorlistPage() {

    const selectedCountry = useSelector(state => state.slectedCountry.value)
    const selectedState = useSelector(state => state.selectedcityState.value)
    const selectedCity = useSelector(state => state.selectedCity.value)
    const searchText = useSelector(state => state.searchText.value)
    const isSearch = useSelector(state => state.isSearch.value)
    const experiance = useSelector(state => state.doctorExeperiance.value)

    const [doctorList, setdoctorList] = useState([])
    const [temp, settemp] = useState([]);
    const [dLoading, setdLoading] = useState(true)
    const [faqs, setfaqs] = useState([])
    const [departmentList, setdepartmentList] = useState([])
    const [deptLoading, setdeptLoading] = useState(false);
    const [loading, setloading] = useState(true)
    const [selectedDept, setselectedDept] = useState(1);

    let PageSize = 6

    const [currentPage, setCurrentPage] = useState(1)

    const currentTableData = React.useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return doctorList.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, doctorList, loading])


    useEffect(() => {
        getDoctorSearch()
    }, [isSearch])

    useEffect(() => {
        getDepartmentList()
        getDoctorFaq()
    }, [])




    const getDoctorSearch = async () => {
        try {
            var response = await axios.post(apiUrl.getDoctorSearch,
                {
                    tab_id: 1,
                    search_text: searchText,
                })

            if (response.data?.data?.doctor) {
                setdoctorList(response.data.data.doctor)
                settemp(response.data.data.doctor)
                setdLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getDoctorFaq = async () => {
        try {
            var response = await axios.get(apiUrl.getAllDoctorFaq)
            if (response.data?.data?.allDoctorFaq) {
                setfaqs(response.data.data.allDoctorFaq)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const filterDoctor = (data) => {
        var tempDoctor = [...doctorList]
        if (data.type === 'Z') {
            tempDoctor.sort((a, b) => b[data.item].localeCompare(a[data.item]))
            setdoctorList(tempDoctor)
        } else {
            tempDoctor.sort((a, b) => a[data.item].localeCompare(b[data.item]))
            setdoctorList(tempDoctor)
        }
    }

    const getDepartmentList = async () => {
        setdeptLoading(true)
        try {
            var response = await axios.get(apiUrl.getDepartmentList)
            if (response.data?.data?.departments) {
                setdepartmentList(response.data?.data?.departments)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setdeptLoading(false)
        }
    }

    const filterDept = (deptId) => {
        setselectedDept(deptId)
        var filteredDoctor = temp.filter(item => item.department_id.includes(deptId.toString()))
        console.log('d',deptId)
        console.log('d',filteredDoctor)
        setdoctorList(filteredDoctor)


    }

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            Previous
        </button>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            Next
        </button>
    );

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        arrow: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <>
            <Hero />
            <section>
                <div className="row main-pd tsc-contr">
                    <Skeleton loading={deptLoading} active>

                        <Slider {...settings} >

                            {departmentList.map(item => <div className="l-one" key={item.id}>
                                <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" align="middle" className={'img-thumbnail', item.id === selectedDept ? 'img-thumbnail dep-img-active' : 'img-thumbnail dep-img'}
                                    width="200" height="200" onClick={() => filterDept(item.id)} />
                                <h3>{item.name}</h3>
                            </div>)}

                        </Slider>
                    </Skeleton>
                </div>
            </section>

            <section>
                <div className="row col-12 doctors main-pd m-0">
                    <div className="tl-contr float-start w-100">
                        <h1 className="text-start float-start">Top Doctors</h1>
                        <div className="float-end">
                            <label className="t-600">Sort By :</label>
                            <ul className="country-contr">
                                <li>
                                    <div className="form-floating">
                                        <select aria-label="Country" id='scountry' onChange={(e) => filterDoctor({ item: 'country_name', type: e.target.value })}>
                                            <option value="" hidden>Country</option>
                                            <option value="A">A-Z</option>
                                            <option value="Z">Z-A</option>

                                        </select>

                                    </div>
                                </li>
                                <li>
                                    <select onChange={(e) => filterDoctor({ item: 'city_name', type: e.target.value })}>
                                        <option value="" hidden>City</option>
                                        <option value="A">A-Z</option>
                                        <option value="Z">Z-A</option>
                                    </select>
                                </li>
                                <li>
                                    <select onChange={(e) => filterDoctor({ item: 'department_name', type: e.target.value })}>
                                        <option value="" hidden>Speciality</option>
                                        <option value="A">A-Z</option>
                                        <option value="Z">Z-A</option>
                                    </select>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <Skeleton loading={dLoading} active>

                        {currentTableData.map(item =>
                            <div className="col-6 dcter-list" key={item.id}>

                                <div className="doctors-list" >
                                    <Link to={`/doctor/${item.id}`}>

                                        <div className="hs-logo">
                                            {/* <img src={hsLogo} alt="" /> */}
                                            {item?.hospital_logo && <img src={`${apiUrl.host}/storage/${item.hospital_logo}`} width="45px" height="21px" alt="" className='doc-img' />}
                                        </div>
                                        <div className="doctor-img">
                                            <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : doctorPlaceHolder} alt="" className='doc-img' />
                                        </div>
                                        <h2 className="t-600">{item.name}</h2>
                                        <div className="exp-contr">{item.experience}+ Yrs EXP</div>
                                        <p>{item.qualification}</p>
                                        <div className="ln-contr">
                                            <p className="t-600">{item.department_name}</p>
                                            <p> <img src={lng} /> <span className="t-600 colr1">Speakes:
                                            </span>
                                                {item?.languages.map(item => <span key={item.id}>{`${item} `}</span>)}
                                            </p>
                                        </div>
                                    </Link>
                                    <div className="links">
                                        <Link to={`/doctor/${item.id}`} className="btcn">Know more</Link>
                                        <Link to={`/doctor/${item.id}`} className="btcn1">Consult now</Link>
                                    </div>

                                </div>
                            </div>)}
                        {doctorList.length === 0 && <Empty />}

                        <div className="text-end">
                            <Pagination style={{ borderColor: 'red' }} size={'large'} current={currentPage} total={doctorList.length} onChange={page => setCurrentPage(page)} />

                        </div>

                    </Skeleton>

                    {!doctorList.length == 0 && <div className="col-12">
                        <div className="text-start best-package">
                            <h2>Still Confuse to find best doctor?</h2>
                            <p>8 out of 10 person requires experienced opinion to find a doctor for their treatment and we have experiences more than a decade.</p>
                            <div className="contact t-600 text-center">
                                Contact now
                            </div>
                        </div>
                    </div>}

                </div>
            </section >

            {faqs.length !== 0 && <Faq faqlist={faqs} />}

        </>
    )
}

export default DoctorlistPage
