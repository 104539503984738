import React, { useState, useEffect } from 'react'
import phoneIcon from '../../assets/images/phone-icon.svg'
import emailIcon from '../../assets/images/email-icon.svg'
import feedbackIcon from '../../assets/images/feedback-icon.svg'
import submitIcon from '../../assets/images/submit-icon.svg'
import axios from 'axios'
import apiUrl from '../../services/apiUrl'
import { useForm } from 'react-hook-form'
import { message } from 'antd'

import { setSettings } from '../../features/settingsSlice'
import { useSelector, useDispatch } from 'react-redux'

import { Link } from 'react-router-dom'

import 'antd/lib/message/style/index.css'


function Footer() {

    const dispatch = useDispatch()
    const settings = useSelector(state => state.settings.value)
    const countryList = useSelector(state => state.countrylist.value)



    const [footerData, setfooterData] = useState([])
    const [email, setemail] = useState('')
    const [submitLoading, setsubmitLoading] = useState(false)
    const { handleSubmit,reset } = useForm()


    useEffect(() => {
        getFooterData()

    }, [])


    const getFooterData = async () => {
        try {

            var response = await axios.get(apiUrl.getFooterData)
            if (response?.data?.status && response?.data?.data?.settings) {
                dispatch(setSettings(response.data.data.settings))
            }

        } catch (error) {
            console.log(error)

        }
    }



    const submitEnquiry = async (e) => {
        setsubmitLoading(true)
        try {
            var response = await axios.post(apiUrl.setCommonEnquiry, { email: email })
            if (response?.data?.status) {
                message.success(response?.data.message || 'Enguiry Recorded Successfully', 3)
                setsubmitLoading(false)
                setemail('')
            } else {
                setsubmitLoading(false)
            }
        } catch (error) {
            console.log(error)
            message.error('Failed to Record Enguiry', 3)
            setsubmitLoading(false)
            reset()
            setemail('')

        }
    }


    return (

        <section>
            <div className="col-12 footer-contr main-pd">
                <div className="row footer-contact m-0">
                    <p className="t-600"><img src={phoneIcon} alt="" /><a href={`tel:${settings.site_phone}`}>{settings.site_phone}</a></p>
                    <p className="t-600"><img src={emailIcon} alt="" /><a href={`mailto:${settings.site_email}`}> {settings.site_email} </a></p>
                    <p className="t-600"><img src={feedbackIcon} alt="" />Feedback or Questions?</p>
                </div>
                <div className="col-12">
                    <div className="row p-0">
                        <div className="col s-full">
                            <h1 className="t-700">Quick Links</h1>
                            <ul>
                                <li><Link to="/hospitals">Hospital</Link></li>
                                <li><Link to="/doctors">Doctor</Link></li>
                                <li><Link to="/medicine">Telemedicine</Link></li>
                                <li><Link to="/treatment/list">Treatment</Link></li>
                                <li><Link to="/blogs">Blog</Link></li>
                            </ul>
                        </div>
                        <div className="col s-full">
                            <h1 className="t-700">Treatments in Countries</h1>
                            <ul>
                                {countryList.map(item => <li key={item.id}><a href="#">{item.name}</a></li>)}
                               
                            </ul>
                        </div>
                        <div className="col s-full1">
                            <h1 className="t-700">Stay <span>connected</span></h1>
                            <p>Neque porro quisquam est qui dolorem ipsum quia dolor</p>
                            <div className="stay">
                                <form onSubmit={handleSubmit(submitEnquiry)}>
                                    <input type="email" onChange={(e) => { setemail(e.target.value) }} value={email} placeholder="Your Email Address" required />
                                    <button type="submit">
                                        {submitLoading ? <div className="spinner-border spinner-border-sm text-white" role="status"></div> : <img src={submitIcon} alt="" />}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="col-12 footer-bottom main-pd">
                <div className="ftr-bottm t-700">
                    Have a comment, question, or want to share something with us?
                </div>
                <p>{settings.site_copyright}</p>
            </div>
            {/* <div className="cc"></div>
            <div className="cc1"></div> */}
        </section>
        


    )
}

export default Footer
